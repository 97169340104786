import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  paper: {
    flex: '1 1 auto',
  },
  wrapper: {
    height: '100%',
    background: theme.evPalette.charcoal[100],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
  claimsLoading: {
    margin: theme.spacing(1.5),
  },
  link: {
    textDecoration: 'none',
    userSelect: 'none',
    cursor: 'pointer',
  },
  disabledLink: {
    cursor: 'not-allowed',
  },
  list: {
    outline: 'none',
  },
  riskShotImage: {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  riskShotImageBox: {
    overflow: 'hidden',
  },
  userIcon: {
    height: theme.spacing(2.25),
    width: theme.spacing(2.25),
    marginRight: theme.spacing(1),
    color: theme.evPalette.charcoal[600],
  },
  adjusterAssignmentDiv: {
    height: theme.spacing(7.375),
  },
  adjusterAssignmentDivHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.evPalette.charcoal[100],
      '& $userIcon': {
        color: theme.evPalette.ocean[500],
      },
    },
  },
  adjusterLabel: {
    lineHeight: '19.07px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.evPalette.nickel[700],
    fontSize: theme.typography.body2.fontSize,
  },
  assignedAdjusterText: {
    lineHeight: '19.07px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.evPalette.charcoal[900],
    fontSize: theme.typography.body2.fontSize,
    width: '140px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  unassignedAdjusterText: {
    lineHeight: '19.07px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.evPalette.charcoal[600],
    fontSize: theme.typography.body2.fontSize,
    width: '140px',
  },
  claimId: {
    width: '170px',
    overflowX: 'auto',
    wordWrap: 'break-word',
  },
  filterIcon: {
    color: theme.evPalette.charcoal[600],
  },
  filterIconOnClick: {
    color: theme.evPalette.ocean[500],
  },
  filterIconButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.evPalette.charcoal[200],
    },
  },
  filterIconButtonOnClick: {
    backgroundColor: theme.evPalette.charcoal[200],
  },
  filterOptionText: {
    marginLeft: theme.spacing(1.875),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.evPalette.charcoal[900],
    lineHeight: '19.07px',
  },
  filterBoxList: {
    height: theme.spacing(12),
    width: theme.spacing(25),
    padding: theme.spacing(0),
  },
  filterBoxListItem: {
    height: theme.spacing(6),
    paddingLeft: theme.spacing(2.875),
  },
  checkboxRoot: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    '&$checked': {
      color: theme.evPalette.ocean[500],
    },
  },
  checked: {},
  overlayTrackingDivHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.evPalette.charcoal[100],
    },
  },
  captureType: {
    background: `${theme.evPalette.iceberg[100]}19`,
    color: theme.evPalette.iceberg[700],
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  rescheduleBtn: {
    textTransform: 'none',
    height: '30px',
    color: theme.evPalette.ocean[600],
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  rescheduleDialogPaper: {
    height: 'auto',
    width: 400,
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  titleText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.evPalette.iceberg[500],
    lineHeight: '27px',
    height: theme.spacing(4),
  },
  closeIconButton: {
    float: 'right',
    padding: theme.spacing(1),
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
  },
  grayLabel: {
    color: theme.evPalette.nickel[700],
  },
  claimDetailLabel: {
    marginTop: theme.spacing(0.5),
    fontWeight: '600',
  },
  select: {
    minWidth: '100%',
    paddingLeft: theme.evSpacing['ev-spacing--12'],
  },
  inputBoxLabel: {
    fontSize: '0.75rem',
    fontWeight: '100',
    marginTop: theme.spacing(0.75),
    marginLeft: theme.evSpacing['ev-spacing--12'],
  },
  iconStatus: {
    float: 'right',
    marginRight: theme.spacing(1),
    top: 'calc(50% - 20px)',
  },
  borderedInputWithLabel: {
    borderRadius: '5px',
    border: `2px solid ${theme.evPalette.charcoal[200]}`,
  },
  otherReasonsInput: {
    width: '100%',
    paddingLeft: theme.evSpacing['ev-spacing--12'],
  },
  modalFooter: {
    float: 'right',
  },
  footerBackBtn: {
    fontSize: '0.75rem',
    marginRight: theme.spacing(0.75),
    color: theme.evPalette.ocean[600],
  },
  footerDoneBtn: {
    fontSize: '0.75rem',
  },
  responseMsg: {
    fontSize: '0.75rem',
  },
  calendarIcon: {
    margin: 'auto',
    width: '50px',
    height: '50px',
  },
  responseHeaderLabel: {
    color: theme.evPalette.ocean[600],
  },
  timeZoneLabel: {
    fontWeight: '100',
    fontSize: '12px',
  },
  releaseNotesText: {
    textTransform: 'none',
  },
  releaseNotesBox: {
    marginRight: '16px',
  },
  homeBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));
