/* eslint-disable max-len */
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      done: 'Done',
      cancel: 'Cancel',
      confirm: 'Confirm',
      continue: 'Continue',
      delete: 'Delete',
      loading: 'Loading...',
      included: 'Included',
      notIncluded: 'Not included',
      reload: 'Reload',
      yes: 'Yes',
      no: 'No',
      okay: 'Okay',
      back: 'Back',
      'home.currentClaims': 'Current Claims',
      'adjuster.justExit': 'Just Exit',
      'adjuster.completeAndExit': 'Complete and Exit',
      'adjuster.exitDialogTitle': 'Set Status To Complete?',
      'adjuster.viewOrdersNotEntitled': 'You do not have permission to view this job currently',
      'home.pastClaims': 'Past Claims',
      'home.searchPlaceholder': 'Search claim ID, address, status...',
      'home.noClaimsFound': 'No claims found.',
      'home.creation': 'Creation',
      'home.claimId': 'Claim/Job ID',
      'home.affiliatedOrgName': 'Affiliated Org Name',
      'home.queue': 'Queue',
      'home.ordered': 'Ordered',
      'home.archive': 'Archive',
      'home.search': 'Search',
      'home.address': 'Address',
      'home.claimNo': 'Claim No.',
      'home.claimOwner': 'Claim Owner',
      'home.claimStatus': 'Claim Status',
      'home.adjuster': 'Adjuster',
      'home.unassigned': 'Unassigned',
      'home.assignedToMe': 'Assigned To Me',
      'home.noJobsAvailable': 'There are no jobs available',
      'home.assignAdjuster': 'Assign Adjuster',
      'home.selectAdjuster': 'Select an adjuster to assign to the following job',
      'home.unassign': 'Unassign',
      'home.cancel': 'Cancel',
      'home.assign': 'Assign',
      'home.assignee': 'Assignee',
      'home.adjusterName': 'Adjuster Name',
      'home.estimatedDelivery': 'Estimated Delivery',
      'home.reschedule': 'Reschedule',
      'home.jobInfoLabel': 'Job Info',
      'home.reasonsLabel': 'Reasons',
      'home.otherReasonsLabel': 'Other (Reason)',
      'home.jobAppointmentDateLabel': 'Current appointment date',
      'home.appointmentDateTimezoneLabel': 'Homeowner Time Zone',
      'home.rescheduleRequested': 'Reschedule Requested',
      'home.notScheduled': 'Not Scheduled',
      'home.notAvailable': 'Not Available',
      'home.expand': 'Expand',
      'home.collapse': 'Collapse',
      'home.progress': 'Progress',
      'home.stepperSteps.ordered': 'Ordered',
      'home.stepperSteps.scheduled': 'Scheduled',
      'home.stepperSteps.inspection': 'Inspection',
      'home.stepperSteps.processed': 'Processing',
      'home.stepperSteps.ready': 'Ready',
      'home.rescheduleInfo': 'You are canceling the current appointment and sending a reschedule request to the homeowner',
      'home.rescheduleSuccessRespMsg': 'The job has been sent to the homeowner for rescheduling',
      'home.rescheduleFailureRespMsg': 'Reschedule of appointment got failed',
      'home.assignmentCancelConfirmation': 'Do you want to proceed without saving the Adjuster details?',
      'home.resultNotFound': 'Result Not Found',
      'home.adjusterListingError':
        'We were unable to load the adjuster list. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'home.appoitnmentDetailsError':
        'We were unable to load the appointment details. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'home.adjusterAssignmentError':
        'We were unable to save the adjuster assignment. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.completeReviewFailure':
        'We were unable to complete the review. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.assessAppSettingsError': 'Unable to load the Application Settings. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>',
      'home.claimsListingError':
        'We were unable to find any claims. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'home.claimsLoadError':
        'We were unable to load the Claims. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'home.readyToReviewError': 'Unable to change the status of claim. Please try again, later',
      'home.referCode': 'Refer code:',
      'home.rescheduleMethodLabel': 'Reschedule Method',
      'home.rescheduleSchedulerPortal': 'Scheduling portal',
      'home.rescheduleHomeOwner': 'Homeowner reschedule',
      'home.rescheduleReasonUnavailable': 'Homeowner Unavailable',
      'home.rescheduleReasonWeather': 'Weather',
      'home.rescheduleReasonWetRoof': 'Wet Roof',
      'home.rescheduleReasonOther': 'Other',
      'droneToMeasure.title': 'Drone To Measure POC',
      'claim.flightType.fullScan': 'Full Scan',
      'claim.flightType.quickCapture': 'Quick Capture',
      'claim.marketing.productName.fullScan': 'Assess Detect',
      'claim.marketing.productName.quickCapture': 'Assess View',
      'claim.marketing.productName.assessMeasure': 'Assess Measure',
      'adjuster.details': 'Details',
      'adjuster.exitDialogContent':
        "Setting status to complete moves the job from 'Current Claims' to 'Past Claims'.",
      'adjuster.somethingWentWrong':
        'We encountered an error. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or email us ',
      'adjuster.tab.policy': 'Policy',
      'adjuster.tab.flightType': 'Flight Type',
      'adjuster.tab.dateCaptured': 'Date Captured',
      'adjuster.tab.dateCreated': 'Date Created',
      'adjuster.tab.capture': 'Capture',
      'adjuster.tab.weather': 'Weather',
      'adjuster.tab.eagleviewReports': 'Eagleview reports',
      'adjuster.tab.dateOfLoss': 'Date of loss',
      'adjuster.tab.perilCode': 'Peril code',
      'adjuster.tab.windDirection': 'Wind direction',
      'adjuster.tab.windGust': 'Wind gust',
      'adjuster.tab.hailSize': 'Hail size',
      'adjuster.tab.stormDate': 'Storm date',
      'adjuster.tab.roofReport': 'Roof report',
      'adjuster.tab.notAvailable': 'Not available',
      'adjuster.tab.processing': 'Processing',
      'adjuster.tab.3dRoofReport': '3D Roof Report',
      'adjuster.tab.orderRoofReport': 'Order 3D Roof Report',
      'adjuster.tab.generateRoofReport': 'Generate 3D Roof Report',
      'adjuster.gallery': 'Elevation',
      'photosGallery.gallery': 'Gallery',
      'adjuster.roof': 'Roof',
      'adjuster.galleryFilter': 'Gallery Filter',
      'adjuster.purchaseReport.amountLabel': 'EagleView 3D Roof Report',
      'adjuster.purchaseReport.deliveryTimeLabel': 'Estimated Delivery Time',
      'adjuster.purchaseReport.descriptionText':
        'Provides a 3D diagram of the roof and aerial images of the property. It also includes the length, area, pitch, and notes diagrams.',
      'adjuster.purchaseReport.unableToProcessText': 'Unfortunately, we are unable to process the measurement report for this property.',
      'adjuster.purchaseReport.reportInProcess': 'Thank you. Your order is being processed.',
      'adjuster.purchaseReport.orderSubmitted': 'Order Submitted',
      'adjuster.purchaseReport.paymentFailed': 'Unable to Process Payment',
      'adjuster.purchaseReport.paymentFailedMsg': 'There was a problem authorizing your payment method. Please verify your billing information within your account settings.',
      'adjuster.purchaseReport.reportUnavailable': '3D Roof Report Unavailable',
      'adjuster.purchaseReport.msgContactUs': 'If you have any questions, please visit our',
      'adjuster.purchaseReport.msgContactUsLinkTxt': 'EagleView customer support page',
      'purchaseReportBtn.cancel': 'Cancel',
      'purchaseReportBtn.order': 'Order',
      'purchaseReportBtn.generate': 'Generate',
      'purchaseReport.generate.delivryTime': '24 Hours',
      'purchaseReport.order.delivryTime': '5-10 minutes',
      'adjuster.saveGalleryImagesOrientation':
        'We were unable to save orientation data for the image. Please try again. If the issue persists, please contact customer support.',
      'adjuster.claimsDetailError':
        'We were unable to find the details of the claim requested. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.baseImage':
        'We were unable to load the aerial image for this property. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.facetImages':
        'We were unable to load high resolution imagery for this facet. You will not be able to view suspected damages or annotate. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.referCode': 'Refer code:',
      'adjuster.claimsNotesDetailError':
        'We were unable to find notes of the claim requested. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.claimsTestSquareNotesDetailError':
        'We were unable to find notes of the test square requested. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.testSquareLocationUpdateError': 'Unable to save the location of test square. If the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.saveFacetAnnotationError': 'Unable to save the annotation, please try again. If the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.facetAnnotationUpdateError': 'Unable to update the facet annotation. If the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.saveUserAnomalyMovementError': 'Unable to save the updated location for the anomaly, please try again. If the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.deleteAnnotationError':
        'We were unable to delete the annotation. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.displayFacetAnnotations':
        'We were unable to display the facet annotations. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.getAnomalyNotesError': 'Unable to get the anomaly notes, please try again.',
      'adjuster.addAnomalyNoteError': 'Unable to add anomaly note, please try again.',
      'adjuster.updateAnomalyNoteError': 'Unable to update anomaly note, please try again.',
      'adjuster.updateDateOfLossError': 'Date of Loss update failed, please try again. If the problem persists, contact your EagleView representative directly or customer service.',
      'adjuster.updateDateOfLossSuccessToast': 'Date of Loss updated successfully. Please refresh your screen',
      'adjuster.updateDateOfLossProgressMessage': 'Date of Loss update in progress. Please refresh your screen in 2-3 minutes to receive the updated roof report.',
      'adjuster.updateDateOfLossFailedMessage': 'Date of Loss update to roof report failed. Please contact customer service.',
      'adjuster.includeAnomalyNoteError': 'Unable to include anomaly note, please try again.',
      'statusbar.tags': 'Tags',
      'statusbar.noTags': 'No tags',
      'adjuster.mapViewerError': 'We encountered an error. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.notes': 'Notes',
      'statusbar.notesTestSquare': 'Test Square',
      'statusbar.noNotes': 'No notes',
      'statusbar.notes.dialog': 'Are you sure you want to delete this note?',
      'statusbar.somethingWentWrong':
      'We encountered an error. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or email us ',
      'workflowpanel.testSquareCheckBox': 'Use Test Square',
      'workflowpanel.remove': 'Remove',
      'workflowpanel.confirm': 'Confirm',
      'workflowpanel.damages': 'Damages',
      'workflowpanel.damage': 'Damage',
      'workflowpanel.decision': 'Facet Decision',
      'workflowpanel.area': 'Area',
      'workflowpanel.anomaly': 'Anomaly',
      'workflowpanel.addDamage': 'Add Damage',
      'workflowpanel.markDamage': 'Mark as Damage',
      'workflowpanel.hide': 'Hide',
      'workflowpanel.anomalies': 'anomalies',
      'workflowpanel.potential': 'potential',
      'workflowpanel.softMetals': 'View Soft Metals',
      'facetImageDialog.facet': 'Facet',
      'workflowpanel.softMetalsError':
        'We were unable to find the soft metal images of the claim requested. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.removed': 'removed',
      'workflowpanel.reinstate': 'Reinstate',
      'workflowpanel.decisionSaveError':
        'We were unable to save the decision. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.addUserAnomalyError':
        'We were unable to save the anomaly. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.anomalyDecisionSaveError':
        'We were unable to save the decision on the anomaly. Please try again. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.userAnomalyDeletConfirm': 'Are you sure you want to delete this anomaly?',
      'workflowpanel.toggleAnomalyIncludeInReportError':
        'We were unable to toggle inclusion of the anomaly. Please try again. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.userAnomalyDeleteError':
        'We were unable to delete the anomaly. Please try again. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.updateAnomalyTypeError': 'Anomaly type update failed, please try again. In case the problem persists, please try refreshing your screen',
      'workflowpanel.anomalyImage':
        'We were unable to load high resolution imagery for this facet. You will not be able to view suspected damages or annotate. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowpanel.testSquareUpdate': 'We were unable to update the test square status. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workflowPanel.confirmed': 'Confirmed',
      'workflowPanel.removed': 'Removed',
      'workflowpanelTooltip.fullSize': 'Full Size',
      'workflowpanelTooltip.fitToWindow': 'Fit to Window',
      'workflowpanelTooltip.rotate': 'Rotate',
      'workflowpanelTooltip.zoomIn': 'Zoom In',
      'workflowpanelTooltip.zoomOut': 'Zoom Out',
      'workflowpanelTooltip.noSoftMetals': 'No soft metals detected',
      'workflowpanel.damageType': 'Type',
      'workflowpanel.hail': 'Hail',
      'workflowpanel.wind': 'Wind',
      'workflowpanel.userDefined': 'User Defined',
      'workflowpanel.nailPop': 'Nail Pop',
      'workflowpanel.blistering': 'Blistering',
      'workflowpanel.mechanical': 'Mechanical',
      'workflowpanel.wearAndTear': 'Wear and Tear',
      'workflowpanel.other': 'Other',
      'statusbar.totalAnomalies': 'Total anomalies',
      'statusbar.heatMap': 'Heat map',
      'statusbar.less': 'less',
      'statusbar.more': 'more',
      'statusbar.fetchReportIncludedImages':
        'We were unable to fetch the list of included images. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.noteSaveError':
        'We were unable to save your note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.noteUpdateError':
        'We were unable to update your note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.noteDeleteError':
        'We were unable to delete your note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.tagSaveError':
        'We were unable to save the tag. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.tagDeleteError':
        'We were unable to delete the tag. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.facetReportInclusionError':
        'We were unable to include this facet. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'statusbar.selector.fewer': 'Fewer',
      'statusbar.selector.recomended': 'Recommended',
      'statusbar.selector.more': 'More',
      'statusbar.selector.label': 'Show',
      'home.claims': 'Claims',
      'adjuster.imagePreview.tagsLabel': 'Tags:',
      'report.title': 'Report',
      'QcPending.title': 'QC Pending',
      'qcPanel.pending': 'QC Pending',
      'qcPanel.approved': 'QC Approved',
      'qcPanel.notapplicable': 'QC Not Applicable',
      'qcPanel.rejected': 'QC Rejected',
      'qcPanel.escalatedTechnical': 'QC Escalated Technical',
      'qcPanel.rescheduled': 'QC Rescheduled',
      'qcPanel.retiepoint': 'QC Retiepointed',
      'qcPanel.enhanced.escalated': 'Escalated',
      'qcPanel.enhanced.escalatedTechnical': 'Escalated - Technical',
      'qcPanel.enhanced.retiepoint': 'Rejected - Tie-pointed',
      'qcPanel.enhanced.rescheduled': 'Rejected - Rescheduled',
      'qcPanel.rejectQcStructureFailed': 'Unable to reject this structure at the moment. Please try again. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.undoQcStructureRejectionFailed': 'Unable to undo the rejection of this structure. Please try again. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.ApprovedFailed': 'QC Decision for this order failed to Accept. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.RejectFailed': 'QC Decision for this order failed to Reject. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.RetiepointFailed': 'QC Decision for this order failed to Retiepoint. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.RescheduleFailed': 'QC Decision for this order failed to Reschedule. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.LoadFailed': 'The qc details can not be fetched.Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcPanel.ApprovedSuccess': 'The QC check for this order has been approved successfully',
      'qcPanel.RejectSuccess': 'The QC check for this order has been rejected successfully',
      'qcPanel.RetiepointSuccess': 'The QC check for this order has been retiepointed successfully',
      'qcPanel.RescheduleSuccess': 'The QC check for this order has been rescheduled successfully',
      'qcPanel.confirmApprove': 'The QC order once approved cannot be reverted back. Please confirm.',
      'qcPanel.confirmReject': 'The QC order once rejected cannot be reverted back. Please confirm.',
      'qcPanel.confirmEscalateTechnical': 'The QC order once escalated with the fresh service ticket generated cannot be reverted back. Please confirm.',
      'qcPanel.confirmReschedule': 'The QC order once rescheduled cannot be reverted back. Please confirm.',
      'qcPanel.confirmRetiepoint': 'The QC order once retiepointed cannot be reverted back. Please confirm.',
      'qcPanel.checkoutByReportIdSuccess': 'Report checked out successfully with report id.',
      'qcPanel.checkoutByReportIdFailed': 'Unable to communicate to ops prime',
      'qcPanel.checkInSimpleFailed': 'Unable to checkin report for you. Please contact your administrator.',
      'qcPanel.rejectSimpleNotesFailed': 'Unable to reject report with notes. Please contact your administrator.',
      'qcPanel.facetNameTableCell': 'Facet',
      'qcPanel.facetCoverageTableCell': 'Coverage',
      'qcPanel.facetAreaTableCell': 'Area',
      'qcPanel.qcStructureRejectConfirmation': 'You are about to reject this structure for the order. You can undo this action though!',
      'qcPanel.qcStructureRejectButtonText': 'Reject Structure',
      'qcPanel.undoRejectedQcStructureButtonText': 'Undo',
      'qcPanel.rejectedQcStructureStatusText': 'Rejected',
      'report.summary': 'SUMMARY',
      'report.summary.placeholder': 'Add summary',
      'report.decision': 'DECISION',
      'report.generating': 'Generating...',
      'report.generate': 'Generate Report',
      'report.viewPrevious': 'View Previous',
      'report.reportVersions': 'Report Versions',
      'report.reportTitle': 'Title',
      'report.submitError':
        'We were unable to save the report. Please try again. If the issue persists, please contact customer support.',
      'report.unableDownload':
        'We have been unable to download the report. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'report.reportVersionsFetchError':
        'We have been unable to fetch the report versions. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'decision.undecided': 'Undecided',
      'decision.repair': 'Repair',
      'decision.replace': 'Replace',
      'decision.nodamage': 'No Damage',
      'reportPanel.noDamage': 'No Damage',
      'reportPanel.replaceRoof': 'Replace Roof',
      'reportPanel.repairRoof': 'Repair Roof',
      'reportPanel.undecided': 'Undecided',
      'statusbar.fetchReportIncludedError':
        'We were unable to fetch report inclusion status. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>',
      'adjuster.outsideOfBounds': 'Please choose a spot inside of the selected facet boundary.',
      'adjuster.outsideOfTestsquare': 'Please choose a location inside the test square boundary.',
      'addAnomaly.outsideFacetImage':
        'Anomalies can only be added on high-resolution images. A high-resolution image is unavailable for this location.',
      'reportPanel.saveRoofDecisionError':
        'We were unable to save the roof decision. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'reportPanel.saveRoofNoteError':
        'We were unable to save the roof note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'reportPanel.updateRoofNoteError':
        'We were unable to update the roof note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'reportPanel.fetchRoofNoteError':
        'We were unable to fetch the roof note. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'home.status.imagesPending': 'Images Pending',
      'home.status.ordered': 'Ordered',
      'home.status.scheduled': 'Scheduled',
      'home.status.inspectionComplete': 'Inspection Complete',
      'home.status.processing': 'Processing',
      'home.status.readyForReview': 'Ready For Review',
      'home.status.completed': 'Completed',
      'home.status.orderClosed': 'Order Closed',
      'home.status.processingStarted': 'Processing Started',
      'home.additionalInfo.orderedTime': 'Order Time',
      'home.additionalInfo.appointmentTime': 'Appointment Time',
      'home.additionalInfo.completedTime': 'Completed Time',
      'home.additionalInfo.processingStarted': 'Processing Started',
      'home.additionalInfo.processingComplete': 'Processing Complete',
      'home.additionalInfo.closedTime': 'Closed Time',
      'galleryError.noImages': 'The gallery has no images.',
      'gallery.unableToCapture': 'Unable to Capture',
      'imagePreview.displayAnnotation':
        'We were unable to display the annotation. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.saveAnnotation':
        'We were unable to save the annotation. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'entitleUser.noEntitlements': 'You do not have permission to this application. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcHome.openOrders': 'Open Orders',
      'qcHome.completedOrders': 'Completed Orders',
      'qcHome.status': 'Status',
      'qcHome.pendingFrom': 'Pending in the current status from',
      'qcHome.completedOn': 'Completed On',
      'qcHome.claims': 'Claims',
      'qcHome.referCode': 'Refer code:',
      'qcHome.qcEscalatedClaims': 'Escalated QC',
      'qcHome.qcPendingClaims': 'Pending QC',
      'qcHome.orderId': 'Order Id',
      'qcHome.claimNo': 'Claim No.',
      'qcHome.address': 'Address',
      'qcHome.coverage': 'Coverage',
      'qcHome.reachedQcState': 'Reached QC State',
      'qcHome.claimsListingError':
        'We were unable to find any claims. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'qcHome.organizationId': 'Organization Id',
      'qcHome.organizationName': 'Organization Name',
      'qcHome.affiliatedOrganizationName': 'Affiliated Org Name',
      'qcHome.pendingAdjustment': 'Pending Adjustment',
      'qcHome.pendingAdjustmentFrom': 'Pending Adjustment from',
      'qcHome.assignedTo': 'Assigned To',
      'qcHome.searchFieldPlaceholderTooltip': 'Search on OrderId, ClaimId, Address, Status, Organization Name and Id',
      'qcHome.workflowStatus': 'Workflow State',
      'qcHome.releaseNotes': 'Release Notes',
      'qcHome.new': 'New',
      'qcPanel.CoveragePercentage': 'Coverage is ',
      'qcPanel.pendingMsg': 'Reached QC Pending',
      'qcPanel.completedMsg': 'Completed in',
      'qcPanel.utc': '(UTC)',
      'qcPanel.hour': 'hour',
      'qcPanel.hours': 'hours',
      'qcPanel.day': 'day',
      'qcPanel.days': 'days',
      'qcPanel.minute': 'minute',
      'qcPanel.minutes': 'minutes',
      'qcPanel.viewtiepoints': 'View Tie Points',
      'qcPanel.viewFacets': 'View Facets',
      'qcPanel.viewSkydio': 'View Skydio coverage mask',
      'qcPanel.skydioImageAltText': 'Skydio Coverage Mask',
      'qcPanel.skydioUnavailable': 'Skydio coverage mask not available',
      'qcPanel.skydioImageDownloadFailure': 'We were unable to download the Skydio Coverage Mask. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'Reschedule.title': 'Reschedule',
      'Reject.title': 'Reject',
      'RejectProperty.title': 'Reject Property',
      'Approve.title': 'Approve',
      'adjuster.qcPendingPanelEscalate': 'Escalate-Review',
      'adjuster.qcPendingPanelEscalateTechnical': 'Escalate-Technical(Help Desk)',
      'adjuster.qcPendingPanelEditTiePoints': 'Edit Tie Points',
      'adjuster.qcPendingPanelReschedule': 'Reschedule',
      'adjuster.qcPendingPanelRetiepoint': 'Retiepoint',
      'adjuster.qcPendingPanelAccept': 'Accept',
      'adjuster.qcPendingPanelAcceptProperty': 'Accept Property',
      'adjuster.qcPendingPanelNotesModalNotes': 'Notes',
      'adjuster.qcPendingPanelNotesModalNotesRequired': '*Required Field',
      'adjuster.qcPendingPanelOrderHeading': 'Order',
      'adjuster.qcPendingPanelPropertyHeading': 'Property',
      'adjuster.qcPendingPanelNotesModalCancel': 'Cancel',
      'adjuster.qcPendingPanelNotesModalSubmit': 'Submit',
      'adjuster.qcPendingPanelRejectOrder': 'Reject Order',
      'adjuster.qcPendingPanelEscalationReason': 'Reason for escalation',
      'adjuster.qcPendingPanelTiePointingReason': 'Reason for tie pointing',
      'adjuster.qcPendingPanelRescheduleReason': 'Reason for rescheduling',
      'adjuster.qcPendingPanelNotesModalRadio': 'Other',
      'adjuster.qcPendingPanelNotesModalCheckbox': 'Assign this task to yourself',
      'adjuster.qcPendingPanelNotesModalCheckboxBadAlignment': 'Bad alignment',
      'adjuster.qcPendingPanelNotesModalCheckboxMosaicGaps': 'Missing imagery -  mosaic gaps',
      'adjuster.qcPendingPanelNotesModalCheckboxFullFacets': 'Missing imagery - full facet(s)',
      'adjuster.qcPendingPanelNotesModalCheckboxPoorlyStitched': 'Poorly stitched mosaic(s)',
      'adjuster.qcPendingPanelNotesModalCheckboxImageryNotLoad': 'Imagery will not load',
      'adjuster.qcPendingPanelNotesModalCheckboxMetalRoof': 'Metal roof',
      'adjuster.qcPendingPanelNotesModalRejectReason': 'Rejection reason',
      'adjuster.qcPendingPanelNotesModalSelectHeading': 'Select a stage to reject the order to:',
      'imagePreview.deleteAnnotation':
        'We were unable to delete the annotation. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.pleaseChooseAccessoryName': 'Please choose an accessory name before saving',
      'imagepreview.toolbar.delete': 'Delete',
      'imagepreview.toolbar.boxAnnotation': 'Box Annotation',
      'imagepreview.toolbar.tagAccessory': 'Tag Accessory',
      'qcHome.title': 'QC Claims',
      'qcHome.contextualMenuItemOpenAsAnAdjuster': 'Open as an Adjuster',
      'qcHome.contextualMenuItemOpenAsMeasurementTech': 'Open as a Measurement Tech',
      'qcHome.contextualMenuResetOrderToReadyForReview': 'Reset Order to ready for review',
      'qcHome.createDuplicateOrder': 'Create Duplicate Order',
      'qcHome.reflyNeededTitle': 'Refly Needed',
      'qcHome.reflyNeededSubmit': 'Submit',
      'qcHome.reflyNeededNotesFieldTitle': 'Notes',
      'qcHome.reflyNeededNotesRequired': '*Required Field',
      'qcHome.reflyNeeded.errorToast': 'We were unable to schedule the order for a refly. Please try again by refreshing your screen.',
      'qcHome.reflyNeeded.successToast': 'This order has been successfully submitted for a refly.',
      'qcHome.resetJobError':
        'We were unable to reset the order to Ready For Review state. Please try again by refreshing your screen.',
      'qcHome.resetJobSuccessToastMsg':
        'The order is being reset. All decisions, annotations, reports and notes will be removed. Test Squares where applicable will move back to original position. Images will be reset to original rotation. Please check the order in Adjuster\'s review screen in a few minutes to see the latest status.',
      'qcHome.resetJobRetainDataSuccessToastMsg':
        'The order is being reset. Please check the order in Adjuster\'s review screen in a few minutes to see the latest status.',
      'qcHome.contextualMenuItemOpenAsQC': 'Open as a QC',
      'qcHome.contextualMenuItemReflyNeeded': 'Refly Needed',
      'qcHome.contextualMenuItemViewSchedulingDetails': 'View Scheduling Details',
      'qcHome.contextualMenuItemViewClaim': 'View Claim',
      'qcHome.contextualMenuItemReopenClaim': 'Reopen Claim',
      'qcHome.orderDuplication.d2m': 'This order can not be duplicated as it is a D2M order',
      'qcHome.orderDuplication.selectOrder': 'Select an order to create a new duplicate order to a specified workflow state',
      'qcHome.orderDuplication.claimNumber': 'Claim Number',
      'qcHome.orderDuplication.organization': 'Organization',
      'qcHome.orderDuplication.workflowState': 'Workflow State',
      'qcHome.orderDuplication.resultNotFound': 'Result Not Found',
      'qcHome.orderDuplication.triageCompleted': 'Triage Completed',
      'qcHome.orderDuplication.manualATPending': 'Manual Adjustment Pending',
      'qcHome.orderDuplication.manualQCPending': 'Manual QC Pending',
      'qcHome.orderDuplication.readyForReview': 'Ready For Review',
      'qcHome.orderDuplication.duplicate': 'Duplicate',
      'qcHome.orderDuplication.duplicationConfirmation': 'OrderID $orderId$ is in process and will be ready to use once completed.',
      'qcHome.orderDuplication.duplicateError': 'We were unable to duplicate the order. Please try again by refreshing your screen.',
      'qcHome.orderDuplication.duplicating': 'Duplicating',
      'qcHome.orderDuplication.orgNotRegistered': 'Duplicate order cannot be created for this organisation as its not registered in inquiry.',
      'qcHome.orderDuplication.noMultistructureOrderDuplication': 'Order can not be duplicated as it is a Multi-structure order.',
      'workflowpanel.testSquareNotAvailable': 'Test square workflow is not available on this facet',
      'workflowpanel.testSquareNotBigEnough': 'Area of facet is not big enough to fit in 10x10\' square',
      'adjusterMenu.completeAndExit': 'Complete & Exit',
      'adjusterMenu.exitOnly': 'Exit only',
      'adjusterMenu.shareReport': 'Share Report',
      'adjusterMenu.generateReport': 'Generate Report',
      'adjusterMenu.exportImagery': 'Export Imagery',
      'adjusterToolbarTooltip.menu': 'Menu',
      'adjusterToolbarTooltip.heatmap': 'Toggle Heatmap',
      'adjusterToolbarTooltip.zoomIn': 'Zoom In',
      'adjusterToolbarTooltip.zoomOut': 'Zoom Out',
      'adjusterToolbarTooltip.boxAnnotation': 'Box Annotation',
      'adjusterToolbarTooltip.deleteBoxAnnotation': 'Delete Box Annotation',
      'adjusterSidePanel.status.readyForReview': 'In review',
      'adjusterSidePanel.status.completed': 'Completed',
      'adjusterSidePanel.status.orderClosed': 'Completed',
      'adjusterSidePanel.status.undeProcess': 'Under Process',
      'adjuster.updateAccessTokenMsg': 'Your session has expired.Refreshing your session.',
      'shareReport.title': 'Report',
      'shareReport.emailInputLabel': 'Share with',
      'shareReport.notesInputLabel': 'Notes',
      'shareReport.characterLimitLabel': 'Character Limit: 2500',
      'shareReport.emailPlaceHolder': 'Email',
      'shareReport.notesPlaceHolder': 'Add Notes',
      'shareReport.sharedWithTitle': 'Already shared with',
      'shareReport.footerButton': 'Share Report',
      'shareReport.shareFailedToastMsg': 'We were unable to share the report. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'shareReport.shareSuccessToastMsg': 'The report has been shared successfully.',
      'shareReport.reportAttempsExposedMsg': 'The report generation limit has been reached. Please contact the individual who provided the report for further assistance.',
      'shareReport.fetchSharedUsersFailedToastMsg': 'We were unable to fetch the list of users with whom the report has already been shared.. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'multiEmailInput.invalidEmail': 'Invalid email address',
      'multiEmailInput.duplicateEmail': 'Email already added',
      'multiEmailInput.maxEmails': 'You can add up to $limit$ emails',
      'multiEmailInput.addEmail': 'Type an email address & press enter, comma, or space to add',
      'exportImagery.title': 'Export Imagery',
      'exportImagery.exportOptions': 'EXPORT OPTIONS',
      'exportImagery.exportReportIncludedImages': 'Images included in report',
      'exportImagery.exportAllImages': 'All images',
      'exportImagery.export': 'Export',
      'exportImagery.unableToDownloadImages':
        'We were unable to download the images. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchImagesError': 'We encountered an error. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchOverviewImagesMetaError': 'Images not found for tie pointing.',
      'manualAtAdjustment.saveError': 'We encountered an error while saving. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.completeError': 'We encountered an error while completing. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchOrderDetailsError': 'We were unable to fetch order details. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.saveAndCompleteSuccess': 'This order has been successfully submitted for ingestion with the inputs provided.',
      'manualAtAdjustment.add': 'Add',
      'manualAtAdjustment.save': 'Save',
      'manualAtAdjustment.reset': 'Reset',
      'manualAtAdjustment.restart': 'Restart',
      'manualAtAdjustment.reject': 'Reject',
      'manualAtAdjustment.complete': 'Complete',
      'manualAtAdjustment.next': 'Next',
      'manualAtAdjustment.nextImage': 'Next Image',
      'manualAtAdjustment.rejectNotes': 'Reject Notes',
      'manualAtAdjustment.manualAtAdjustment': 'Manual Adjustment',
      'manualAtAdjustment.step1': 'Step 1 of 2: Select an oblique image below to start tie pointing',
      'manualAtAdjustment.orthographicImage': 'Orthographic image',
      'manualAtAdjustment.roofReportImage': 'Eagleview Roof Report Image',
      'manualAtAdjustment.coordinates': 'Coordinates: ',
      'manualAtAdjustment.saveDialogTitle': 'Save coordinate pairings',
      'manualAtAdjustment.saveDialogContent': 'This action will save the coordinates and dispatch the workflow completion. Do you wish to continue?',
      'manualAtAdjustment.checkoutOpsPrimeErrorFailed': 'Unable to Communicate to Ops Prime',
      'manualAtAdjustment.checkoutOpsPrimeErrorNotActive': 'Order not active in OPS prime Queue',
      'manualAtAdjustment.completeMessage': 'Manual tie point completed',
      'manualAtAdjustment.rejectPanelCrash': 'Unable to display panel for rejecting manual tie point. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchHistoryError': 'We were unable to fetch notes. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.statusBarCrash': 'Unable to display status bar. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.rejectedDueTo': 'Rejected due to',
      'manualAtAdjustment.withComment': 'with comment',
      'manualAtAdjustment.notEntitledError': 'You are not authorized to see manual tie points.',
      'manualAtAdjustment.pairingsLessThanRecommendedWarningLine1': 'Are you sure you want to complete?',
      'manualAtAdjustment.pairingsLessThanRecommendedWarningLine2a': 'pairings are recommended.',
      'manualAtAdjustment.pairingsLessThanRecommendedWarningLine2bYouHave': 'You have',
      'manualAtAdjustment.pairingsLessThanRecommendedWarningLine2bCurrently': 'pairings currently.',
      'manualAtAdjustment.addOverviewPointWarningOrthoPointNotSelected': 'Select one of the ortho image points on the left hand side before trying to plot a tie point on the right hand side.',
      'manualAtAdjustment.addOverviewPointWarningOrthoPointSelectedButAlreadyPaired': 'Pairing already completed. Select existing tie point to move it to the desired location or delete it and plot a new one.',
      'manualAtAdjustment.escalate': 'Escalate',
      'manualAtAdjustment.skipStructure': 'Skip Structure',
      'manualAtAdjustment.reportSelection': 'Report Selection',
      'manualAtAdjustment.structureNumber': 'Structure $num$ of $count$',
      'manualAtAdjustment.facetImage': 'Facet Image',
      'manualAtAdjustment.reports': 'Reports ($count$)',
      'manualAtAdjustment.report': 'Report $num$',
      'manualAtAdjustment.structure': 'Structure $num$',
      'manualAtAdjustment.mainStructure': 'Main Structure',
      'manualAtAdjustment.fetchStructureDetailsFailure': 'We were unable to fetch details for the structure. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchReportDetailsFailure': 'We were unable to fetch details for the report. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.fetchReportImagesFailure': 'We were unable to fetch images for the report. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.facetImageDownloadFailure': 'We were unable to download the facet image for the structure. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.saveTiePointFailure': 'We were unable to save the tie-pointing for the structure. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.skydioImageAltText': 'Skydio Coverage Mask Image',
      'manualAtAdjustment.skydioUnavailable': 'Skydio mosaic coverage mask image is not available',
      'manualAtAdjustment.skydioImageDownloadError': 'Error while downloading skydio mosaic coverage mask image',
      'manualAtAdjustment.skydioImageDownloadFailure': 'We were unable to download the skydio mosaic coverage mask image for the structure. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.resetOrderTiePointsFailure': 'We were unable to delete and reset the tie points for the order. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtAdjustment.resetConfirmationMessage': 'Resetting will clear all the tie-points saved so far for the order. Do you wish to continue?',
      'manualAtAdjustment.skipAndNextTitle': 'Skip and go to next structure?',
      'manualAtAdjustment.skipStructureConfirmationMessage': 'Skipping a structure will remove the aerial component in the Assess order. This action cannot be reversed.',
      'manualAtAdjustment.nextStructure': 'Next Structure',
      'manualAtAdjustment.cancelSkip': 'Cancel Skip',
      'manualAtAdjustment.skipAndCompleteTitle': 'Skip and complete property?',
      'manualAtAdjustment.completeProperty': 'Complete Property',
      'manualAtAdjustment.skipStructureFailure': 'We were unable to skip structure for the order. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      // Manual AT Reject Panel
      'manualAtRejectPanel.title': 'Reason For Rejection',
      'manualAtRejectPanel.wrongHouse': 'Wrong House',
      'manualAtRejectPanel.remeasureWrongHouse': 'Remeasure Wrong House',
      'manualAtRejectPanel.noUsablePoints': 'No Usable Points',
      'manualAtRejectPanel.other': 'Other',
      'manualAtRejectPanel.requeue': 'Requeue',
      'manualAtRejectPanel.remeasure': 'Remeasure',
      'manualAtRejectPanel.needsReview': 'Needs Review',
      'manualAtRejectPanel.cancel': 'Cancel',
      'manualAtRejectPanel.submit': 'Submit',
      'manualAtRejectPanel.rejectError': 'We encountered an error. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'manualAtRejectPanel.rejectSuccessMessage': 'Successfully rejected the order',
      'manualAtRejectPanel.notesTitle': 'Notes',
      'manualAtRejectPanel.emptyNotesErrorMessage': 'Notes cannot be empty. Please enter notes.',
      'manualAtRejectPanel.emptyRejectReasonNotSelected': 'Reject reason is mandatory. Please select a reason.',
      'manualAtRejectPanel.notesPlaceholder': 'Enter Notes *',
      'manualAtAdjustment.restartDialogTitle': 'Restart Manual Adjustment',
      'manualAtAdjustment.restartDialogContent': 'This action will reset the images selected and the actions performed. Do you wish to continue?',
      'manualAtStatusBar.Pairings': 'Pairings',
      'manualAtStatusBar.Recommended': 'Recommended',
      'manualAtStatusBar.Completed': 'Completed',
      'manualAtStatusBar.Minimum': 'Minimum',
      'manualAtAdjustment.loadObliques': 'Load More',
      'galleryToolbarTooltip.menu': 'Menu',
      'galleryMenu.completeAndExit': 'Complete & Exit',
      'galleryMenu.exitOnly': 'Exit only',
      'galleryMenu.shareReport': 'Share Report',
      'galleryMenu.generateReport': 'Generate Report',
      'galleryMenu.exportImagery': 'Export Imagery',
      'galleryMenu.confirmCompleteAndExit.dialogTitle': 'Complete & Exit',
      'galleryMenu.confirmCompleteAndExit.dialogMsg': 'You have selected \'Complete and Exit\' which will lock the current order, and prevent any further edits.  Are you sure you wish to proceed?',
      // UI Crash translations
      // Adjuster
      'adjuster.sidePanelCrash': 'Unable to display details for this order, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.mapViewerCrash': 'Unable to display images for this address, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.adjusterToolbarCrash': 'Unable to display image tools, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.imagePreviewCrash': 'Unable to display image previewer, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.reportGeneratorCrash': 'Unable to display panel for generating reports, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.exportImageryPanelCrash': 'Unable to display panel for exporting imagery, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.shareReportPanelCrash': 'Unable to display panel for share report, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.qcPendingPanelCrash': 'Unable to display panel for recording QC decisions, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.workflowPanelCrash': 'Unable to display panel for reviewing and recording facet decisions, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.statusbarCrash': 'Unable to display additional information bar at the bottom, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.accessoriesSummaryPanelCrash': 'Unable to display accessories summary data, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.qcSkydioMosaicImagePanelCrash': 'Unable to display panel viewing the Skydio coverage mask. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      // SidePanel
      'sidePanel.sidePanelGallery': 'Gallery',
      'sidePanel.sidePanelDetailsCrash': 'Unable to display details for this order, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'sidePanel.sidePanelRoofCrash': 'Unable to display facet information for this order, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'sidePanel.sidePanelGalleryCrash': 'Unable to display image gallery, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      // ImagePreview
      'imagePreview.mapViewerCrash': 'Unable to display image, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.imagePreviewerToolbarCrash': 'Unable to display image tools, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.reportGeneratorCrash': 'Unable to display panel for generating reports, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.reportShareCrash': 'Unable to display panel for sharing reports, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'imagePreview.exportImageryPanelCrash': 'Unable to display panel for exporting imagery, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      // WorkflowPanel
      'workFlowPanel.softMetalDialogCrash': 'Unable to display soft metals on this facet, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'workFlowPanel.mapViewerCrash': 'Unable to display image, Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      // AccessoriesPanel
      'accessoriesPanel.header': 'Accessories',
      'accessoriesPanel.noAccessories': 'No accessory information is available for the property.',
      'RoofSidePanel.facetToolTip': 'This Facet under-hangs another facet and cannot be reviewed',
      // AnnotatioonDescPanel
      'annotatioonDescPanel.save': 'Save',
      'annotatioonDescPanel.descPlaceholder': 'Type here to add description',
      // Anomaly Description Panel
      'anomalyDescPanel.descPlaceholderEditAllowed': 'Type here to add description.',
      'anomalyDescPanel.descPlaceholderEditNotAllowed': 'No note added.',
      // MultiStructure Menu
      'multiStructureMenu.disabledStructureTooltip': 'Unable to capture imagery for this structure',
      'adjuster.unableToFetchFacetScanImages':
        'We were unable to fetch the facet scan images. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'adjuster.unableToFetchProductCost':
        'We were unable to fetch the product cost. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'utc.noDroneImg': 'No drone imagery available',
      'utc.noGalleryImg': 'No Gallery imagery available',
      'utc.hint': 'Other property imagery can be found in the ',
      'utc.roof': 'Roof',
      'utc.gallery': 'Gallery',
      'utc.sidepanel.roof': 'No Facets',
      'utc.sidepanel.gallery': 'No Images',
      'softMetal.fetchReportIncludedImages':
        'We were unable to fetch the list of included soft metal images. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'softMetal.imageReportInclusionError': 'Unable to include soft metal image to report. Please try again.',
      'softMetal.imageReportRemovingError': 'Unable to remove soft metal image from report. Please try again.',
      'skydio.fetchReportIncludedImages':
        'We were unable to fetch the list of included skydio images. Please try again by refreshing your screen. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'skydio.imageReportInclusionError': 'Unable to include skydio image to report. Please try again.',
      'skydio.imageReportRemovingError': 'Unable to remove skydio image from report. Please try again.',
      'rawImagery.includeInReportText': 'Add selected image to report',
      'pageNotFound.internalServerError': '500 Internal Server Error',
      'pageNotFound.pageNotFound': '404 Page Not Found',
      'pageNotFound.internalServerErrorMsg': 'Please Refresh your browser or retry the page later',
      'pageNotFound.pageNotFoundMsg': 'The requested URL was not found.',
      'pageNotFound.linkExpired': 'Link is no longer valid',
      'pageNotFound.loading': 'loading...',
      'rawImagery.fetchImageAnomalyError': 'Unable to fetch anomalies for the image.Please try again.',
      'photos-ui.totalRoofOverviews': 'TOTAL FACET OVERVIEWS :',
      'photos-ui.totalScans': 'TOTAL IMAGES : ',
      'photos-ui.facetScanImagery': 'Facet scan Imagery',
      'photos-ui.facetScanImageryNotAvailable': 'Facet scan imagery not available',
      'photos-ui.missingSummaryJson.capturedImagery': 'Captured Imagery',
      'photos-ui.facetOverviewImagery': 'Facet Overview Imagery',
      'photos-ui.unsupported': 'We’re sorry, but the ‘Photos’ gallery isn’t available for this order. You can download your images from this page. Thank you.',
      downloadImageryError: 'We are unable to Download at the moment. Please try again later. In case the problem persists, please contact your EagleView representative directly or <a href="https://pictometry.secure.force.com/WebToCaseAssess/" target="_blank">contact customer service</a>.',
      'download-imagery-in-progress': 'Processing Download. This may take a few minutes.',
      'download-imagery-start': 'Download Imagery',
      'photos-ui.selectStructure': 'Select Structure',
      'photos-ui.tagsNotFound': 'Tags not found',
      'shareLogin.signIn': 'Sign In',
      'shareLogin.invalidOptRetries': 'Invalid OTP entered. Remaining retries',
      'shareLogin.enterOtpDesc': 'Enter One Time Password (OTP) Received by Email.',
      'shareLogin.validateButton': 'Validate',
      'shareLogin.resendOtp': 'Resend OTP',
      'shareLogin.notANumber': 'Please enter a valid number for OTP',
      'shareLogin.lockedDueToInvalidAttemptsMsg1': 'Shared Report is locked due to ',
      'shareLogin.lockedDueToInvalidAttemptsMsg2': ' invalid OTP entries and will be unlocked after ',
      'shareLogin.contactCustomerService': 'Contact Customer Support',
      'shareLogin.customerServiceEmail': 'customerservice@eagleview.com',
      'shareLogin.generatingOtp': 'Generating OTP...',
      'shareLogin.ErrorGeneratingOTP': 'Error while generating OTP',
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    resources,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
