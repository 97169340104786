import * as util from 'layout/adjuster/Adjuster.utils';
import * as photosUtil from 'layout/photos/photos.utils';
import { get, isEmpty } from 'lodash';
import {
  FETCH_FULL_IMAGE_SUCCESS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAILURE,
  SET_CURRENT_VIEW,
  FETCH_THUMBNAILS,
  FETCH_THUMBNAILS_SUCCESS,
  FETCH_THUMBNAILS_FAILURE,
  FETCH_RISK_SHOT,
  FETCH_RISK_SHOT_SUCCESS,
  FETCH_RISK_SHOT_FAILURE,
  FETCH_PHOTO_ASSETS,
  FETCH_PHOTO_ASSETS_SUCCESS,
  FETCH_PHOTO_ASSETS_FAILURE,
  FETCH_ORDER_DETAILS,
  RESET_FACET_OVERVIEW,
  FETCH_FULL_IMAGE,
  INITIATE_DOWNLOAD_ZIP_SUCCESS,
  INITIATE_DOWNLOAD_ZIP_FAILURE,
  CLEAR_ZIP_STATUS_ID,
  ZIP_STATUS_SUCCESS,
  ZIP_STATUS_FAILURE,
  SET_SELECTED_STRUCTURE_ID,
  SET_CURRENT_TAB,
  GET_ALL_TAGS_FROM_S3,
  GET_ALL_TAGS_FROM_S3_SUCCESS,
  GET_ALL_TAGS_FROM_S3_FAILURE,
  GET_GROUND_THUMBNAILS,
  GET_GROUND_THUMBNAILS_SUCCESS,
  GET_GROUND_THUMBNAILS_FAILURE,
  SET_SELECTED_GALLEY_IMAGE_NAME,
  FETCH_FULL_GALLERY_IMAGE,
  FETCH_FULL_GALLERY_IMAGE_SUCCESS,
  FETCH_FULL_GALLERY_IMAGE_FAILURE,
} from './photos.constant';

const initialState = {
  fullImages: {
    overviewImage: {},
    facetOverview: {},
  },
  orderDetails: {},
  currentView: {
    zoom: 1,
    rotation: 0,
    lonLat: {
      lon: 0,
      lat: 0,
    },
  },
  claimId: '',
  riskShot: {},
  addressStreet: '',
  addressCity: '',
  policyInsurancePerilCode: '',
  policyDateOfLoss: undefined,
  loading: {},
  apiSuccess: {
    [FETCH_PHOTO_ASSETS]: false,
  },
  exploreOverviewMeta: {},
  facetOverviewMeta: {},
  facetOverviews: [],
  facetOverviewsCount: 0,
  isSummaryJsonAvailable: false,
  totalFacetScans: 0,
  thumbnails: {
    facet_overview: [],
    facet_scan: [],
  },
  disabledMosaic: true,
  cameraPositions: {
    facet_overviews: [],
    facet_scans: [],
  },
  zipDownloadStatusId: '',
  zipDownloadTriggerSuccess: false,
  toastAutoHideDuration: 10000,
  selectedStructureId: 'structure1',
  currentTab: 0,
  photosUITags: [],
  groundThumbnails: [],
  selectedImageNameGalleryTab: '',
  galleryFullImage: '',
};

const photosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PHOTO_ASSETS:
    case FETCH_ORDER_DETAILS:
    case FETCH_FULL_IMAGE:
    case FETCH_RISK_SHOT:
    case FETCH_THUMBNAILS:
      return {
        ...state,
        loading: { ...state.loading, [type]: true },
      };
    case FETCH_RISK_SHOT_SUCCESS:
      return {
        ...state,
        riskShot: !isEmpty(payload.images) ? get(payload, 'images[0]', 'not found') : '',
        loading: { ...state.loading, [FETCH_RISK_SHOT]: false },
      };
    case FETCH_RISK_SHOT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_RISK_SHOT]: false },
      };
    case FETCH_FULL_IMAGE_SUCCESS: {
      const { imageType } = payload;
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FULL_IMAGE]: false },
        fullImages: {
          ...state.fullImages,
          overviewImage: imageType === 'exploreOverview' ? payload.image : state.fullImages.overviewImage,
          facetOverview: imageType === 'facetOverview' ? payload.image : state.fullImages.facetOverview,
        },
      };
    }
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: payload,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
        ...util.mapAdjusterDetails(payload),
        multiStructure: {
          isMultiStructure: get(payload, 'IsMultiStructure', false),
          structures: get(payload, 'Structures', []),
        },
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        errorToastMessage: 'adjuster.claimsDetailError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        loading: { ...state.loading, [FETCH_ORDER_DETAILS]: false },
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: {
          ...state.currentView,
          ...payload,
        },
      };
    case FETCH_PHOTO_ASSETS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loading: { ...state.loading, [FETCH_PHOTO_ASSETS]: false },
        apiSuccess: { ...state.apiSuccess, [FETCH_PHOTO_ASSETS]: true },
        currentView: {
          zoom: 1,
          rotation: 0,
          lonLat: {
            lon: get(data, 'explore_overview_image.corners.br.lon', 0),
            lat: get(data, 'explore_overview_image.corners.br.lat', 0),
          },
        },
        exploreOverviewMeta: get(data, 'explore_overview_image', state.exploreOverviewMeta),
        facetOverviewMeta: get(data, 'facet_overview_image', {}),
        cameraPositions: {
          [payload.requestType]: get(data, `${payload.requestType}`, []),
        },
        facetOverviewsCount: get(data, 'facet_overviews_count', state.facetOverviewsCount),
        isSummaryJsonAvailable: get(data, 'summary_json_available', false),
        totalFacetScans: get(data, 'total_facet_scan_count', state.totalFacetScans),
      };
    }
    case FETCH_PHOTO_ASSETS_FAILURE:
      return {
        ...state,
        errorToastMessage: 'adjuster.baseImage',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
        loading: { ...state.loading, [FETCH_PHOTO_ASSETS]: false },
        apiSuccess: { ...state.apiSuccess, [FETCH_PHOTO_ASSETS]: false },
        currentView: {
          zoom: 1,
          rotation: 0,
          lonLat: {
            lon: 0,
            lat: 0,
          },
        },
        exploreOverviewMeta: {},
        facetOverviewsCount: 0,
        isSummaryJsonAvailable: false,
        totalFacetScans: 0,
      };
    case FETCH_THUMBNAILS_SUCCESS: {
      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [payload.imageType]: payload.thumbnails.images,
        },
        loading: { ...state.loading, [FETCH_THUMBNAILS]: false },
      };
    }
    case FETCH_THUMBNAILS_FAILURE:
      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [payload.imageType]: [],
        },
        loading: { ...state.loading, [FETCH_THUMBNAILS]: false },
      };
    case RESET_FACET_OVERVIEW:
      return {
        ...state,
        fullImages: {
          ...state.fullImages,
          facetOverview: {},
        },
        thumbnails: {
          ...state.thumbnails,
          facet_scan: [],
        },
        cameraPositions: {
          ...state.cameraPositions,
          facet_scans: [],
        },
      };
    case SET_SELECTED_STRUCTURE_ID:
      return {
        ...state,
        selectedStructureId: payload,
      };
    case INITIATE_DOWNLOAD_ZIP_SUCCESS: {
      return {
        ...state,
        zipDownloadStatusId: payload.uuid,
        zipDownloadTriggerSuccess: payload.success,
      };
    }
    case INITIATE_DOWNLOAD_ZIP_FAILURE: {
      return {
        ...state,
        zipDownloadStatusId: '',
        errorToastMessage: 'downloadImageryError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    }
    case ZIP_STATUS_SUCCESS:
      return {
        ...state,
        zipDownloadStatusId: '',
      };
    case ZIP_STATUS_FAILURE:
      return {
        ...state,
        zipDownloadStatusId: '',
        errorToastMessage: 'downloadImageryError',
        errorCode: payload.errorCode,
        severity: 'error',
        showErrorToast: true,
      };
    case CLEAR_ZIP_STATUS_ID:
      return {
        ...state,
        zipDownloadStatusId: '',
        zipDownloadTriggerSuccess: false,
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      };
    case GET_ALL_TAGS_FROM_S3:
      return {
        ...state,
        loading: { ...state.loading, [GET_ALL_TAGS_FROM_S3]: true },
        apiSuccess: { ...state.apiSuccess, [GET_ALL_TAGS_FROM_S3]: false },
        photosUITags: [],
        galleryFullImage: '',
      };
    case GET_ALL_TAGS_FROM_S3_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [GET_ALL_TAGS_FROM_S3]: false },
        apiSuccess: { ...state.apiSuccess, [GET_ALL_TAGS_FROM_S3]: true },
        photosUITags: photosUtil.getPhotosUITags(payload),
      };
    case GET_ALL_TAGS_FROM_S3_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [GET_ALL_TAGS_FROM_S3]: false },
        apiSuccess: { ...state.apiSuccess, [GET_ALL_TAGS_FROM_S3]: false },
        photosUITags: [],
      };
    case GET_GROUND_THUMBNAILS:
      return {
        ...state,
        loading: { ...state.loading, [GET_GROUND_THUMBNAILS]: true },
        groundThumbnails: [],
      };
    case GET_GROUND_THUMBNAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [GET_GROUND_THUMBNAILS]: false },
        groundThumbnails: payload,
      };
    case GET_GROUND_THUMBNAILS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [GET_GROUND_THUMBNAILS]: false },
      };
    case SET_SELECTED_GALLEY_IMAGE_NAME: {
      return {
        ...state,
        selectedImageNameGalleryTab: payload,
      };
    }
    case FETCH_FULL_GALLERY_IMAGE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FULL_GALLERY_IMAGE]: true },
        galleryFullImage: '',
      };
    case FETCH_FULL_GALLERY_IMAGE_SUCCESS: {
      return {
        ...state,
        galleryFullImage: payload,
        loading: { ...state.loading, [FETCH_FULL_GALLERY_IMAGE]: false },
      };
    }
    case FETCH_FULL_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [FETCH_FULL_GALLERY_IMAGE]: false },
      };
    default:
      return state;
  }
};

export default photosReducer;
