import React, { useState, useEffect } from 'react';
import {
  EVBadge,
  EVBox,
  EVButton,
  useTheme,
  EVReactVirtualized,
  EVTextField,
  EVSearchIcon,
  EVIconButton,
  EVFilterVerticalIcon,
  EVMenu,
  EVMenuItem,
  EVCheckbox,
  EVTypography,
} from '@eagleview/ev-comp-library';
import ToastMessage from 'components/toast-message';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import isEmpty from 'lodash/isEmpty';
import { getUser } from 'utils/auth.utils';
import HomeTabs from './home-tabs/HomeTabs';
import HomeAdjusterAssignmentPanel from './home-adjuster-assignment-panel/HomeAdjusterAssignmentPanel';
import HomeClaimTrackPanel from './home-claim-track-panel/HomeClaimTrackPanel';
import * as action from './Home.actions';
import useStyles from './Home.styles';
import HomeVirtualizedList from './home-virtualized-list/HomeVirtualizedList';
import {
  claimStatus,
  TABS,
  FETCH_CURRENT_CLAIMS,
  FETCH_PAST_CLAIMS,
  ASSIGNMENT_FILTER_OPTIONS,
} from './Home.constants';

const { AutoSizer } = EVReactVirtualized;

/**
 * Home represents landing screen of application
 */
const Home = () => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { pendo } = window;

  // selectors
  const showErrorToast = useSelector((state) => state.homeReducer.showErrorToast);
  const errorToastMessage = useSelector((state) => state.homeReducer.errorToastMessage);
  const errorCode = useSelector((state) => state.homeReducer.errorCode);
  const tab = useSelector((state) => state.homeReducer.tab);
  const savedTab = useSelector((state) => state.homeReducer.savedTab);
  const currentClaims = useSelector((state) => state.homeReducer.currentClaims);
  const pastClaims = useSelector((state) => state.homeReducer.pastClaims);
  const searchQuery = useSelector((state) => state.homeReducer.searchQuery);
  const loading = useSelector((state) => state.homeReducer.loading);
  const currentUser = useSelector((state) => state.homeReducer.currentUser);
  const checkedAssignmentFilterOptions = useSelector((state) => state.homeReducer.checkedAssignmentFilterOptions);
  const showAssignmentPanel = useSelector((state) => state.homeReducer.showAssignmentPanel);
  const claimUnderAssignment = useSelector((state) => state.homeReducer.claimUnderAssignment);
  const showClaimTrackPanel = useSelector((state) => state.homeReducer.showClaimTrackPanel);
  const claimBeingTracked = useSelector((state) => state.homeReducer.claimBeingTracked);

  // actions
  const dispatch = useDispatch();
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const fetchCurrentClaims = () => dispatch(action.fetchCurrentClaimsAction());
  const fetchPastClaims = () => dispatch(action.fetchPastClaimsAction());
  const setSearchQuery = (payload) => dispatch(action.setSearchQueryAction(payload));
  const setTab = (payload) => dispatch(action.setTabAction(payload));
  const setSavedTab = (payload) => dispatch(action.setSavedTabAction(payload));
  const setCurrentUser = (payload) => dispatch(action.setCurrentUserAction(payload));
  const setCheckedAssignmentFilterOptions = (payload) => dispatch(action.setCheckedAssignmentFilterOptionsAction(payload));

  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filterBoxAnchorEl, setFilterBoxAnchorEl] = useState(null);
  const [ownerFilterOptionMapping, setOwnerFilterOptionMapping] = useState({ [ASSIGNMENT_FILTER_OPTIONS.UNASSIGNED]: '' });
  const [isNewInVisible, setIsNewInVisible] = useState(true);

  // derived values
  const fuse = new Fuse([...currentClaims, ...pastClaims], {
    keys: ['claimId', 'address', 'address2', 'status', 'orderId'],
  });
  const renderClaims = {
    queueClaims: currentClaims.filter((claim) => claim.status === claimStatus.READYFORREVIEW),
    orderedClaims: currentClaims.filter((claim) => claim.status !== claimStatus.READYFORREVIEW),
    archiveClaims: pastClaims,
    searchedClaims: fuse.search(searchQuery).map((x) => x.item),
  };
  const currentClaimsTab = clsx({
    queueClaims: tab === TABS.QUEUE,
    orderedClaims: tab === TABS.ORDERED,
    archiveClaims: tab === TABS.ARCHIVE,
    searchedClaims: tab === TABS.SEARCH,
  });
  const currentTabLoading = {
    [TABS.QUEUE]: loading[FETCH_CURRENT_CLAIMS],
    [TABS.ORDERED]: loading[FETCH_CURRENT_CLAIMS],
    [TABS.ARCHIVE]: loading[FETCH_PAST_CLAIMS],
    [TABS.SEARCH]: loading[FETCH_CURRENT_CLAIMS] || loading[FETCH_PAST_CLAIMS],
  };
  const alertMsg = `${t(errorToastMessage)} ${errorCode ? `${t('home.referCode')} ${errorCode}` : ''}`;

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (isEmpty(value)) {
      setTab(savedTab);
    }
    if (tab !== TABS.SEARCH && !isEmpty(value)) {
      setSavedTab(tab);
      setTab(TABS.SEARCH);
    }
  };

  // on mount
  useEffect(() => {
    setCurrentUser(getUser());
    fetchCurrentClaims();
    fetchPastClaims();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.userName) {
      setOwnerFilterOptionMapping({
        ...ownerFilterOptionMapping,
        [ASSIGNMENT_FILTER_OPTIONS.ASSIGNED_TO_ME]: currentUser.userName,
      });
    }
  }, [currentUser]);

  const filteredClaimsList = checkedAssignmentFilterOptions.length > 0
    ? renderClaims[currentClaimsTab].filter((claim) => (checkedAssignmentFilterOptions.some((option) => (
      ownerFilterOptionMapping[option] === claim.claimOwnerEmailId))))
    : renderClaims[currentClaimsTab];

  const handleFilterButtonClick = (event) => {
    if (!showFilterBox) {
      setFilterBoxAnchorEl(event.currentTarget);
      setShowFilterBox(true);
    } else {
      setFilterBoxAnchorEl(null);
      setShowFilterBox(false);
    }
  };

  const handleFilterOptionClicked = (option) => {
    if (checkedAssignmentFilterOptions.includes(option)) {
      setCheckedAssignmentFilterOptions(checkedAssignmentFilterOptions.filter((o) => o !== option));
    } else {
      setCheckedAssignmentFilterOptions([...checkedAssignmentFilterOptions, option]);
    }
  };

  const checkIsNewInVisible = () => {
    if (isEmpty(pendo) || isEmpty(pendo.guides)) {
      return true;
    }
    const releaseNotesGuides = pendo.guides.filter((guide) => guide.name.includes('Assess-Release-Notes'));
    if (releaseNotesGuides.length > 0) {
      const sortedReleaseNotesGuides = releaseNotesGuides.sort((first, second) => {
        const firstDate = new Date(first.createdAt);
        const secondDate = new Date(second.createdAt);
        return secondDate - firstDate;
      });
      const guide = sortedReleaseNotesGuides[0];
      return guide.hasBeenSeen();
    }
    return true;
  };

  useEffect(() => {
    if (pendo && isEmpty(pendo.guides)) {
      return;
    }
    const isVisible = checkIsNewInVisible();
    setIsNewInVisible(isVisible);
  }, [pendo]);

  const handleNewReleaseNotes = () => {
    if (isEmpty(pendo) || isEmpty(pendo.guides)) {
      return;
    }
    const releaseNotesGuides = pendo.guides.filter((guide) => guide.name.includes('Assess-Release-Notes'));
    if (releaseNotesGuides.length > 0) {
      const sortedReleaseNotesGuides = releaseNotesGuides.sort((first, second) => {
        const firstDate = new Date(first.createdAt);
        const secondDate = new Date(second.createdAt);
        return secondDate - firstDate;
      });
      const guide = sortedReleaseNotesGuides[0];
      setIsNewInVisible(true);
      pendo.showGuideById(guide.id);
    }
  };

  return (
    <>
      <EVBox className={styles.wrapper}>
        <EVBox
          mb={theme.evSpacing['ev-spacing--16']}
          mt={theme.evSpacing['ev-spacing--16']}
          pl={theme.evSpacing['ev-spacing--32']}
          pr={theme.evSpacing['ev-spacing--32']}
          flex="0 0 auto"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <EVBox flex="0 0 auto">
            <EVBox className={styles.homeBox}>
              <HomeTabs />
              <EVBox className={styles.releaseNotesBox}>
                <EVBadge badgeContent={t('qcHome.new')} color="secondary" variant="text" invisible={isNewInVisible}>
                  <EVButton color="primary" onClick={handleNewReleaseNotes} className={styles.releaseNotesText}>
                    {t('qcHome.releaseNotes')}
                  </EVButton>
                </EVBadge>
              </EVBox>
            </EVBox>
          </EVBox>
          <EVBox flex="0 0 auto" display="flex">
            <EVIconButton
              className={clsx(styles.filterIconButton, showFilterBox && styles.filterIconButtonOnClick)}
              onClick={handleFilterButtonClick}
              data-testid="filterIconButton"
            >
              <EVFilterVerticalIcon className={clsx(styles.filterIcon, showFilterBox && styles.filterIconOnClick)} />
            </EVIconButton>
            <EVBox clone bgcolor="white" borderRadius={0.5} width="375px">
              <EVTextField
                InputProps={{
                  startAdornment: (
                    <EVBox mr={1.5} display="flex" justifyContent="center" alignItems="center">
                      <EVSearchIcon />
                    </EVBox>
                  ),
                }}
                placeholder={t('home.searchPlaceholder')}
                size="small"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchInput}
              />
            </EVBox>
          </EVBox>
        </EVBox>
        <EVBox className={styles.paper}>
          {currentTabLoading[tab] && (
            <EVBox pl={4} pr={4}>
              {t('loading')}
            </EVBox>
          )}
          {!currentTabLoading[tab] && !renderClaims[currentClaimsTab].length && (
            <EVBox pl={4} pr={4}>
              {t('home.noClaimsFound')}
            </EVBox>
          )}
          {!currentTabLoading[tab] && renderClaims[currentClaimsTab].length > 0 && !(filteredClaimsList.length > 0) && (
            <EVBox pl={4} pr={4}>
              {t('home.noJobsAvailable')}
            </EVBox>
          )}
          <AutoSizer>
            {({ height, width }) => (
              <HomeVirtualizedList
                width={width}
                height={height}
                claimsList={filteredClaimsList}
              />
            )}
          </AutoSizer>
        </EVBox>
      </EVBox>
      <EVMenu
        classes={{
          list: styles.filterBoxList,
        }}
        anchorEl={filterBoxAnchorEl}
        keepMounted
        open={showFilterBox}
        onClose={handleFilterButtonClick}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        data-testid="filterMenu"
      >
        {Object.keys(ASSIGNMENT_FILTER_OPTIONS).map((option) => (
          <EVMenuItem
            key={option}
            className={styles.filterBoxListItem}
            onClick={() => handleFilterOptionClicked(ASSIGNMENT_FILTER_OPTIONS[option])}
            data-testid="filterMenuItem"
          >
            <EVCheckbox
              classes={{
                root: styles.checkboxRoot,
                checked: styles.checked,
              }}
              checked={checkedAssignmentFilterOptions.includes(ASSIGNMENT_FILTER_OPTIONS[option])}
              onChange={() => handleFilterOptionClicked(ASSIGNMENT_FILTER_OPTIONS[option])}
              data-testid="filterCheckbox"
            />
            <EVTypography variant="body2" className={styles.filterOptionText}>{t(`home.${ASSIGNMENT_FILTER_OPTIONS[option]}`)}</EVTypography>
          </EVMenuItem>
        ))}
      </EVMenu>
      {showAssignmentPanel && <HomeAdjusterAssignmentPanel claimData={claimUnderAssignment} />}
      {showClaimTrackPanel && <HomeClaimTrackPanel claimData={claimBeingTracked} />}
      <ToastMessage
        alertMsg={alertMsg}
        severity="error"
        open={showErrorToast}
        onClose={handleToastClose}
        autoHideDuration={10000}
      />
    </>
  );
};

export default Home;
