export const generateKey = async (keyString) => {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(keyString);

  return window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'AES-GCM' },
    false,
    ['encrypt', 'decrypt'],
  );
};

export const encryptAES = async (plainText, secretKey = process.env.REACT_APP_AESKEY) => {
  const key = await generateKey(secretKey);
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector (IV)
  const encoder = new TextEncoder();
  const cipherText = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encoder.encode(plainText),
  );
  const ivBase64 = btoa(String.fromCharCode(...iv));
  const cipherTextBase64 = btoa(String.fromCharCode(...new Uint8Array(cipherText)));

  return encodeURIComponent(`${ivBase64}:${cipherTextBase64}`);
};

export const decryptAES = async (combinedString, secretKey = process.env.REACT_APP_AESKEY) => {
  const key = await generateKey(secretKey);

  const decodedString = decodeURIComponent(combinedString);

  const [ivBase64, cipherTextBase64] = decodedString.split(':');
  if (!ivBase64 || !cipherTextBase64) {
    throw new Error('Invalid combined string format.');
  }
  const iv = Uint8Array.from(atob(ivBase64), (c) => c.charCodeAt(0));
  const cipherText = Uint8Array.from(atob(cipherTextBase64), (c) => c.charCodeAt(0));

  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    cipherText,
  );

  const decoder = new TextDecoder();
  return decoder.decode(decrypted);
};
