import React, { useState, useEffect } from 'react';
import {
  EVBox, EVPaper, EVTypography, EVSearchIcon, EVTextField, EVTooltip, EVButton, EVBadge,
} from '@eagleview/ev-comp-library';
import ToastMessage from 'components/toast-message';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS, QC_ENHANCEMENT } from 'layout/entitleUser/EntitleUser.constants';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import QcHomeTable from './qc-home-table/QcHomeTable';
import QcHomeTabs from './qc-home-tabs/QcHomeTabs';
import * as action from './QcHome.actions';
import {
  FETCH_QC_ESCALATED_CLAIMS,
  FETCH_QC_PENDING_CLAIMS,
  TABS,
  FETCH_MANUAL_AT_CLAIMS,
  FETCH_OPEN_ORDERS,
  FETCH_COMPLETED_ORDERS,
  MIN_SEARCH_TERM_LENGTH,
} from './QcHome.constants';
import useStyles from './QcHome.styles';

/**
 * QcHome to display QC claims
 */
const QcHome = () => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const { orderId: orderIdParam, tab: tabParam } = useParams();
  const { pendo } = window;

  // local state
  const [localSearchTerm, setLocalSearchTerm] = useState('');

  // selectors
  const loading = useSelector((state) => state.qcHomeReducer.loading);
  const showErrorToast = useSelector((state) => state.qcHomeReducer.showErrorToast);
  const errorToastMessage = useSelector((state) => state.qcHomeReducer.errorToastMessage);
  const errorCode = useSelector((state) => state.qcHomeReducer.errorCode);
  const toastDuration = useSelector((state) => state.qcHomeReducer.toastDuration);
  const toastSeverity = useSelector((state) => state.qcHomeReducer.toastSeverity);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const currentTab = useSelector((state) => state.qcHomeReducer.currentTab);
  const searchTerm = useSelector((state) => state.qcHomeReducer.searchTerm);
  const enableQcEnhancement = useSelector((state) => get(state.entitleUserReducer.featureFlags, QC_ENHANCEMENT, false));

  // actions
  const dispatch = useDispatch();
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const fetchQcEscalatedClaims = () => dispatch(action.fetchQcEscalatedClaimsAction());
  const fetchQcPendingClaims = () => dispatch(action.fetchQcPendingClaimsAction());
  const fetchManualAtClaims = () => dispatch(action.fetchManualAtClaimsAction());
  const fetchOpenOrders = () => dispatch(action.fetchOpenOrdersAction());
  const fetchCompletedOrders = () => dispatch(action.fetchCompletedOrdersAction());
  const setTab = (payload) => dispatch(action.setCurrentTab(payload));
  const updateSearchTerm = (payload) => dispatch(action.updateSearchTermAction(payload));
  const fetchAssessAppSettings = () => dispatch(action.fetchAssessAppSettingsAction());
  const fetchInternalSchedulerEntitlements = () => dispatch(action.fetchInternalSchedulerEntitlementsAction());
  const [isNewInVisible, setIsNewInVisible] = useState(true);

  // derived data
  const currentTabLoading = {
    [TABS.QC_ESCALATED]: loading[FETCH_QC_ESCALATED_CLAIMS],
    [TABS.QC_PENDING]: loading[FETCH_QC_PENDING_CLAIMS],
    [TABS.PENDING_ADJUSTMENT]: loading[FETCH_MANUAL_AT_CLAIMS],
    [TABS.OPEN_ORDERS]: loading[FETCH_OPEN_ORDERS],
    [TABS.COMPLETED_ORDERS]: loading[FETCH_COMPLETED_ORDERS],
  };

  const fetchCurrentTabData = {
    [TABS.QC_ESCALATED]: () => {
      if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS)) {
        fetchQcEscalatedClaims();
      }
    },
    [TABS.QC_PENDING]: () => {
      if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS)) {
        fetchQcPendingClaims();
      }
    },
    [TABS.PENDING_ADJUSTMENT]: () => {
      if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS)) {
        fetchManualAtClaims();
      }
    },
    [TABS.OPEN_ORDERS]: () => fetchOpenOrders(),
    [TABS.COMPLETED_ORDERS]: () => fetchCompletedOrders(),
  };

  useEffect(() => {
    if (localSearchTerm === '' || (localSearchTerm.length >= MIN_SEARCH_TERM_LENGTH && localSearchTerm !== searchTerm)) {
      updateSearchTerm(localSearchTerm);
    }
  }, [localSearchTerm]);

  const setInitialTab = () => {
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_QC_DECISIONS)) {
      setTab(enableQcEnhancement ? TABS.QC_ESCALATED : TABS.QC_PENDING);
    } else if (
      isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS)
    ) {
      setTab(TABS.PENDING_ADJUSTMENT);
    } else {
      setTab(TABS.OPEN_ORDERS);
    }
  };

  // on mount
  useEffect(() => {
    fetchAssessAppSettings();
    fetchInternalSchedulerEntitlements();
    setInitialTab();
  }, []);

  useEffect(() => {
    if (currentTab && !currentTabLoading[currentTab]) {
      fetchCurrentTabData[currentTab]();
    }
  }, [currentTab]);

  useEffect(() => {
    if (orderIdParam && orderIdParam !== searchTerm) {
      setLocalSearchTerm(orderIdParam);
    }
    if (tabParam && Object.values(TABS).includes(tabParam) && tabParam !== currentTab) {
      setTab(tabParam);
    }
  }, [orderIdParam, tabParam]);

  const handleSearchTermChange = (e) => {
    const { value } = e.target;
    setLocalSearchTerm(value);
  };

  const handleOnBlurSearchField = () => {
    if (localSearchTerm !== '' && localSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
      setLocalSearchTerm(searchTerm);
    }
  };

  const checkIsNewInVisible = () => {
    if (isEmpty(pendo) || isEmpty(pendo.guides)) {
      return true;
    }
    const releaseNotesGuides = pendo.guides.filter((guide) => guide.name.includes('Assess-Release-Notes'));
    if (releaseNotesGuides.length > 0) {
      const sortedReleaseNotesGuides = releaseNotesGuides.sort((first, second) => {
        const firstDate = new Date(first.createdAt);
        const secondDate = new Date(second.createdAt);
        return secondDate - firstDate;
      });
      const guide = sortedReleaseNotesGuides[0];
      return guide.hasBeenSeen();
    }
    return true;
  };

  useEffect(() => {
    if (pendo && isEmpty(pendo.guides)) {
      return;
    }
    const isVisible = checkIsNewInVisible();
    setIsNewInVisible(isVisible);
  }, [pendo]);

  const handleNewReleaseNotes = () => {
    if (isEmpty(pendo) || isEmpty(pendo.guides)) {
      return;
    }
    const releaseNotesGuides = pendo.guides.filter((guide) => guide.name.includes('Assess-Release-Notes'));
    if (releaseNotesGuides.length > 0) {
      const sortedReleaseNotesGuides = releaseNotesGuides.sort((first, second) => {
        const firstDate = new Date(first.createdAt);
        const secondDate = new Date(second.createdAt);
        return secondDate - firstDate;
      });
      const guide = sortedReleaseNotesGuides[0];
      setIsNewInVisible(true);
      pendo.showGuideById(guide.id);
    }
  };

  return (
    <>
      <EVBox className={styles.wrapper}>
        <EVBox className={styles.main}>
          <EVBox display="flex" justifyContent="space-between">
            <EVBox display="flex">
              <EVTypography variant="h1" gutterBottom>
                {t('qcHome.claims')}
              </EVTypography>
              <EVBadge badgeContent={t('qcHome.new')} color="secondary" variant="text" invisible={isNewInVisible}>
                <EVButton color="primary" onClick={handleNewReleaseNotes} className={styles.releaseNotesText}>
                  {t('qcHome.releaseNotes')}
                </EVButton>
              </EVBadge>
            </EVBox>
            <EVBox flex="0 0 auto">
              <EVTooltip title={t('qcHome.searchFieldPlaceholderTooltip')}>
                <EVTextField
                  inputProps={{ 'data-testid': 'search-field' }}
                  // eslint-disable-next-line
                  InputProps={{
                    startAdornment: (
                      <EVBox mr={1.5} display="flex" justifyContent="center" alignItems="center">
                        <EVSearchIcon />
                      </EVBox>
                    ),
                  }}
                  className={styles.searchField}
                  placeholder={t('qcHome.searchFieldPlaceholderTooltip')}
                  size="small"
                  variant="outlined"
                  value={localSearchTerm}
                  onChange={handleSearchTermChange}
                  onBlur={handleOnBlurSearchField}
                />
              </EVTooltip>
            </EVBox>
          </EVBox>
          <QcHomeTabs />
          <EVPaper elevation={1} className={styles.paper}>
            {currentTabLoading[currentTab] ? (
              <EVTypography className={styles.claimsLoading} variant="body1" data-testid="data-loading">{t('loading')}</EVTypography>
            ) : (
              <QcHomeTable />
            )}
          </EVPaper>
        </EVBox>
      </EVBox>
      <ToastMessage
        alertMsg={`${t(errorToastMessage)} ${errorCode ? `${t('qcHome.referCode')} ${errorCode}` : ''}`}
        severity={toastSeverity}
        open={showErrorToast}
        onClose={handleToastClose}
        autoHideDuration={toastDuration}
      />
    </>
  );
};

export default QcHome;
