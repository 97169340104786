import React, { useEffect } from 'react';
import {
  EVTabs,
  EVTab,
  EVSidePanel,
  EVBox,
  EVTypography,
  EVLandscapeIcon,
  EVImagePreviewer,
  EVChip,
} from '@eagleview/ev-comp-library';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import * as action from 'layout/adjuster/Adjuster.actions';
import * as photosAction from 'layout/photos/Photos.actions';
import toUpper from 'lodash/toUpper';
import clsx from 'clsx';
import ErrorBoundary from 'components/ErrorBoundary';
import { ASSESS_MARKETING_PRODUCTNAMES, ASSESS_GROUND_PHOTO_GALLERY } from 'layout/entitleUser/EntitleUser.constants';
import { isEmpty } from 'lodash';
import {
  statusToTranslation,
} from 'layout/adjuster/Adjuster.constants';
import { TABS } from 'layout/photos/photos.constant';
import useStyles from './PhotosSidePanel.styles';
import SidePanelDetails from './SidePanelDetails';
import SidePanelGallery from './SidePanelGallery';
import { claimStatus, MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME } from '../../homeLayout/home/Home.constants';
import { GROUND_IMAGE_TYPE, RISK_IMAGE_TAG, FETCH_RISK_SHOT } from '../photos.constant';

/**
 * Represents photos UI side panel
 */
const PhotosSidePanel = ({ setResize }) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const setSidePanelWidth = (payload) => dispatch(action.setSidePanelWidthAction(payload));
  const fetchRiskshotAction = (payload) => dispatch(photosAction.fetchRiskshotAction(payload));
  const setCurrentTabAction = (payload) => dispatch(photosAction.setCurrentTabAction(payload));

  // selectors
  const claimId = useSelector((state) => state.photosReducer.claimId);
  const orderId = useSelector((state) => state.photosReducer.orderId);
  const addressCity = useSelector((state) => state.photosReducer.addressCity);
  const addressStreet = useSelector((state) => state.photosReducer.addressStreet);
  const orderStatus = useSelector((state) => state.photosReducer.orderStatus);
  const isAssessLite = useSelector((state) => state.photosReducer.isAssessLite);
  const flightType = useSelector((state) => state.photosReducer.flightType);
  const enableMarketingProductNames = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_MARKETING_PRODUCTNAMES, false));
  const loading = useSelector((state) => state.photosReducer.loading);
  const riskShot = useSelector((state) => state.photosReducer.riskShot);
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));
  const currentTab = useSelector((state) => state.photosReducer.currentTab);
  const imageryUploadComplete = useSelector((state) => get(state, 'photosReducer.imageryUploadComplete', false));
  const isSummaryJsonAvailable = useSelector((state) => get(state, 'photosReducer.isSummaryJsonAvailable', false));
  const structures = useSelector((state) => get(state, 'photosReducer.multiStructure.structures', []));
  const selectedStructure = structures.find((structure) => structure.structureID === selectedStructureId);
  const isGroundCaptured = get(selectedStructure, 'isGroundCaptured', true);

  // derived values
  const disableFocusOnGalleryTab = clsx(styles.tabBar, currentTab === TABS.GALLERY && styles.tabBarNoFocus);

  const colorByStatus = {
    Ordered: styles.inReviewChip,
    Scheduled: styles.inReviewChip,
    InspectionComplete: styles.inReviewChip,
    Processing: styles.inReviewChip,
    ReadyForReview: styles.inReviewChip,
    Completed: styles.completedChip,
    OrderClosed: styles.completedChip,
  };

  const displayOrderStatus = () => {
    if ([claimStatus.READYFORREVIEW, claimStatus.COMPLETED, claimStatus.ORDERCLOSED].includes(orderStatus)) {
      return statusToTranslation[orderStatus];
    } return 'adjusterSidePanel.status.undeProcess';
  };

  const tabHandler = (e, id) => {
    setCurrentTabAction(id);
  };
  const onWidthChange = (val) => {
    setSidePanelWidth(val);
    setTimeout(() => {
      setResize();
    }, 0);
  };

  const getRiskShotBlob = () => `data:image/png;base64,${riskShot.imageBytes}`;

  const orderTitle = () => {
    if (enableMarketingProductNames) {
      return MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME[flightType];
    }
    return isAssessLite ? 'claim.flightType.quickCapture' : '';
  };

  const isAssessGroundPhotoGalleryEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_GROUND_PHOTO_GALLERY,
    false,
  ));

  useEffect(() => {
    if (!isEmpty(orderId)) {
      fetchRiskshotAction({
        orderId,
        structureId: selectedStructureId,
        imageType: GROUND_IMAGE_TYPE,
        groundImageTag: RISK_IMAGE_TAG,
      });
    }
  }, [orderId, selectedStructureId]);

  return (
    <EVSidePanel
      visibilityToggleEnabled
      resizeHandleEnabled={false}
      option={currentTab}
      visibility="show"
      width="370px"
      elevation={6}
      height="100%"
      className={styles.sidepanel}
      onWidthChange={onWidthChange}
      toggleButtonClass={styles.collapseButton}
    >
      <EVBox className={styles.riskShowWrapper}>
        { !(loading[FETCH_RISK_SHOT]) && !isEmpty(riskShot) ? (
          <div className={styles.riskShotStyle}>
            <EVImagePreviewer
              url={getRiskShotBlob()}
              detectWheel={false}
              disableDoubleClickZoomWithToolAuto
              tool="none"
              disableFitToWindow
              disableFullSize
              disableRotate
              disableZoomIn
              disableZoomOut
            />
          </div>
        ) : (
          <EVBox
            width="100%"
            height="240px"
            bgcolor="grey.300"
            color="common.white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EVLandscapeIcon data-testid="laodingIcon" style={{ fontSize: 100 }} />
          </EVBox>
        )}
      </EVBox>
      <EVBox className={styles.claimDetails}>
        <EVBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={styles.claimDetailsHeader}
        >
          <EVTypography variant="h4" className={styles.claimId}>
            {claimId}
          </EVTypography>
          <EVBox display="flex" justifyContent="flex-end" alignItems="center">
            {!isEmpty(orderTitle()) && (
              <EVChip
                size="small"
                className={clsx(styles.captureType, styles.chipStyle)}
                label={toUpper(t(orderTitle()))}
              />
            )}
            <EVChip
              className={clsx(colorByStatus[orderStatus], styles.chipStyle)}
              label={toUpper(t(displayOrderStatus()))}
            />
          </EVBox>
        </EVBox>
        <EVTypography variant="h3" gutterBottom className={styles.addressStreetStyle}>
          {addressStreet}
        </EVTypography>
        <EVTypography variant="h4">{addressCity}</EVTypography>
      </EVBox>
      {isAssessLite !== null && (
        <>
          <EVTabs
            value={currentTab}
            onChange={tabHandler}
            textColor="primary"
            indicatorColor="primary"
            className={styles.tabs}
          >
            <EVTab
              className={disableFocusOnGalleryTab}
              value={TABS.DETAILS}
              label={t('adjuster.details')}

            />
            <EVTab
              disabled={
                !isAssessGroundPhotoGalleryEnabled || !imageryUploadComplete
                || (!isSummaryJsonAvailable && !isGroundCaptured)
              }
              className={disableFocusOnGalleryTab}
              value={TABS.GALLERY}
              label={t('photosGallery.gallery')}
              data-testid="galleryButton"
            />
          </EVTabs>
          {(currentTab === TABS.DETAILS) && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelDetails"
              alertMsg={t('sidePanel.sidePanelDetailsCrash')}
            >
              <SidePanelDetails />
            </ErrorBoundary>
          )}
          {currentTab === TABS.GALLERY && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelGallery"
            >
              <SidePanelGallery />
            </ErrorBoundary>
          )}
        </>
      )}
    </EVSidePanel>
  );
};

PhotosSidePanel.propTypes = {
  setResize: func,
};

PhotosSidePanel.defaultProps = {
  setResize: () => { // Empty block
  },
};

export default PhotosSidePanel;
