const prefix = 'photosReducer';

export const DEFAULT_ZOOM_LEVEL = 17;
export const PHOTOS_MAX_ZOOM_LEVEL = 35;

export const GROUND_IMAGE_TYPE = 'ground';

export const RISK_IMAGE_TAG = 'risk';

export const FETCH_FULL_IMAGE = `${prefix}/FETCH_FULL_IMAGE`;
export const FETCH_FULL_IMAGE_SUCCESS = `${prefix}/FETCH_FULL_IMAGE_SUCCESS`;
export const FETCH_FULL_IMAGE_FAILURE = `${prefix}/FETCH_FULL_IMAGE_FAILURE`;

export const FETCH_ORDER_DETAILS = `${prefix}/FETCH_ORDER_DETAILS`;
export const FETCH_ORDER_DETAILS_SUCCESS = `${prefix}/FETCH_ORDER_DETAILS_SUCCESS`;
export const FETCH_ORDER_DETAILS_FAILURE = `${prefix}/FETCH_ORDER_DETAILS_FAILURE`;

export const FETCH_THUMBNAILS = `${prefix}/FETCH_THUMBNAILS`;
export const FETCH_THUMBNAILS_SUCCESS = `${prefix}/FETCH_THUMBNAILS_SUCCESS`;
export const FETCH_THUMBNAILS_FAILURE = `${prefix}/FETCH_THUMBNAILS_FAILURE`;

export const FETCH_RISK_SHOT = `${prefix}/FETCH_RISK_SHOT`;
export const FETCH_RISK_SHOT_SUCCESS = `${prefix}/FETCH_RISK_SHOT_SUCCESS`;
export const FETCH_RISK_SHOT_FAILURE = `${prefix}/FETCH_RISK_SHOT_FAILURE`;

export const FETCH_PHOTO_ASSETS = `${prefix}/FETCH_PHOTO_ASSETS`;
export const FETCH_PHOTO_ASSETS_SUCCESS = `${prefix}/FETCH_PHOTO_ASSETS_SUCCESS`;
export const FETCH_PHOTO_ASSETS_FAILURE = `${prefix}/FETCH_PHOTO_ASSETS_FAILURE`;

export const INITIATE_DOWNLOAD_ZIP = `${prefix}/INITIATE_DOWNLOAD_ZIP`;
export const INITIATE_DOWNLOAD_ZIP_SUCCESS = `${prefix}/INITIATE_DOWNLOAD_ZIP_SUCCESS`;
export const INITIATE_DOWNLOAD_ZIP_FAILURE = `${prefix}/INITIATE_DOWNLOAD_ZIP_FAILURE`;

export const ZIP_STATUS = `${prefix}/ZIP_STATUS`;
export const ZIP_STATUS_SUCCESS = `${prefix}/ZIP_STATUS_SUCCESS`;
export const ZIP_STATUS_FAILURE = `${prefix}/ZIP_STATUS_FAILURE`;

export const CLEAR_ZIP_STATUS_ID = `${prefix}/CLEAR_ZIP_STATUS_ID`;
export const CLEAR_ZIP_STATUS_ID_SUCCESS = `${prefix}/CLEAR_ZIP_STATUS_ID_SUCCESS`;
export const CLEAR_ZIP_STATUS_ID_FAILURE = `${prefix}/CLEAR_ZIP_STATUS_ID_FAILURE`;

export const GET_ALL_TAGS_FROM_S3 = `${prefix}/GET_ALL_TAGS_FROM_S3`;
export const GET_ALL_TAGS_FROM_S3_SUCCESS = `${prefix}/GET_ALL_TAGS_FROM_S3_SUCCESS`;
export const GET_ALL_TAGS_FROM_S3_FAILURE = `${prefix}/GET_ALL_TAGS_FROM_S3_FAILURE`;

export const GET_GROUND_THUMBNAILS = `${prefix}/GET_GROUND_THUMBNAILS`;
export const GET_GROUND_THUMBNAILS_SUCCESS = `${prefix}/GET_GROUND_THUMBNAILS_SUCCESS`;
export const GET_GROUND_THUMBNAILS_FAILURE = `${prefix}/GET_GROUND_THUMBNAILS_FAILURE`;

export const FETCH_FULL_GALLERY_IMAGE = `${prefix}/FETCH_FULL_GALLERY_IMAGE`;
export const FETCH_FULL_GALLERY_IMAGE_SUCCESS = `${prefix}/FETCH_FULL_GALLERY_IMAGE_SUCCESS`;
export const FETCH_FULL_GALLERY_IMAGE_FAILURE = `${prefix}/FETCH_FULL_GALLERY_IMAGE_FAILURE`;

export const SET_SELECTED_GALLEY_IMAGE_NAME = `${prefix}/SET_SELECTED_GALLEY_IMAGE_NAME`;

export const SET_CURRENT_TAB = `${prefix}/SET_CURRENT_TAB`;

export const SET_SELECTED_STRUCTURE_ID = `${prefix}/SET_SELECTED_STRUCTURE_ID`;

export const RESET_FACET_OVERVIEW = `${prefix}/RESET_FACET_OVERVIEW`;

export const SET_CURRENT_VIEW = `${prefix}/SET_CURRENT_VIEW`;

export const CLOSE_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;

export const TIME_INTERVAL_FOR_ZIP_POLL = 180 * 1000;

export const PROCESSING_READY_TO_CAPTURE_PAYMENT_STATUS = 'processingreadytocapturepayment';

export const TABS = {
  DETAILS: 0,
  GALLERY: 1,
};

export const tagNameMap = {
  risk: 'Risk',
  explore_overview: 'Explore Overview',
  explore_obliques: 'Explore Obliques',
  front_elevation: 'Front Elevation',
  back_elevation: 'Back Elevation',
  right_elevation: 'Right Elevation',
  left_elevation: 'Left Elevation',
  interior_images: 'Interior Images',
  manual: 'Manual',
  additional_images: 'Additional Images',
  capture_mask: 'Capture Mask',
};

export const FIX_HEIGHT = 538;
