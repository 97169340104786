import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EVBox, EVTypography, EVIconButton, EVClear, EVInputLabel, EVSelect,
  EVMenuItem, EVExpandMore, EVTextField, EVButton, EVDivider,
} from '@eagleview/ev-comp-library';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import calendarIcon from 'assets/calendar.svg';
import { get } from 'lodash';
import {
  ASSESS_RESCHEDULE_SCHEDULER,
} from 'layout/entitleUser/EntitleUser.constants';
import { encryptAES } from 'utils/cryptoUtils';
import useStyles from '../Home.styles';
import * as actions from '../Home.actions';
import {
  RESCHEDULE_REASONS, OTHERS_OPT, MAX_REJECT_REASON_LENGTH, EMPTY_STRING,
  RESCHEDULE_METHODS, RESCHEDULE_METHOD_SCHEDULER_PORTAL, SCHEDULER_PORTAL_BASE_ADDRESS,
} from '../Home.constants';

const RescheduleAppointmentDialog = ({ claimData, closeDialogPanel }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();

  const fetchedAppointmentDetails = useSelector((state) => get(state.homeReducer, 'appointmentDetails', {}));
  const rescheduleResponseMsg = useSelector((state) => get(state.homeReducer, 'rescheduleResponseMsg', null));
  const isRescheduleSuccess = useSelector((state) => get(state.homeReducer, 'rescheduleCompleted', false));

  const clearAppointmentDetails = () => dispatch(actions.clearAppointmentDetails());
  const rescheduleAppointment = (payload) => dispatch(actions.rescheduleAppointment(payload));
  const fetchCurrentClaims = () => dispatch(actions.fetchCurrentClaimsAction());
  const clearRescheduleResp = () => dispatch(actions.clearRescheduleAppointmentResp());

  const [selectedReason, selectReason] = useState(Object.keys(RESCHEDULE_REASONS)[0]);
  const [selectedMethod, selectMethod] = useState(Object.keys(RESCHEDULE_METHODS)[1]);
  const [otherReason, setOtherReason] = useState('');
  const [showResponseContent, setResponseContent] = useState(false);

  const isSchedulerEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_RESCHEDULE_SCHEDULER,
    false,
  ));

  const appointmentStartTime = moment.utc(fetchedAppointmentDetails.appointmentStartTime).format('DD MMM, YYYY hh:mm a');

  const submit = () => {
    const body = {
      reasonCode: selectedReason,
    };
    if (selectedReason === OTHERS_OPT) body.notes = otherReason;
    rescheduleAppointment({
      orderId: claimData.orderId,
      body,
    });
  };

  const handleRejectReasonChange = (event) => {
    const inputReasonReason = get(event, 'target.value', EMPTY_STRING);
    if (inputReasonReason.length <= MAX_REJECT_REASON_LENGTH) {
      setOtherReason(inputReasonReason);
    } else {
      setOtherReason(inputReasonReason.substr(0, 50));
    }
  };

  const closePanel = (refreshList = false) => {
    closeDialogPanel();
    clearAppointmentDetails();
    clearRescheduleResp();
    if (refreshList) fetchCurrentClaims();
  };

  useEffect(() => {
    const generateKey = async (params) => {
      const urlParams = await encryptAES(JSON.stringify(params));
      return urlParams;
    };

    const handleReschedule = async () => {
      if (isSchedulerEnabled && selectedMethod === RESCHEDULE_METHOD_SCHEDULER_PORTAL && isRescheduleSuccess) {
        const params = {
          orderId: claimData.orderId,
          claimId: claimData.claimId,
          homeOwnerDetails: fetchedAppointmentDetails.homeOwnerDetails,
          jobPropertyAddress: fetchedAppointmentDetails.jobPropertyAddress,
          previousAppointment: {
            appointmentEndTime: fetchedAppointmentDetails.appointmentEndTime,
            appointmentStartTime: fetchedAppointmentDetails.appointmentStartTime,
          },
        };
        const cipher = await generateKey(params);
        window.location.href = `${SCHEDULER_PORTAL_BASE_ADDRESS}?reschedule=${cipher}`;
      } else if (rescheduleResponseMsg.length) setResponseContent(true);
    };
    handleReschedule();
  }, [rescheduleResponseMsg]);

  const renderJobInfo = () => (
    <>
      <EVBox mt={3}>
        <EVTypography className={styles.grayLabel} variant="body2">
          {t('home.jobInfoLabel')}
        </EVTypography>
      </EVBox>
      <EVBox>
        <EVTypography className={styles.claimDetailLabel}>
          {claimData.claimId}
        </EVTypography>
        <EVTypography className={styles.claimDetailLabel}>
          {claimData.address}
        </EVTypography>
      </EVBox>
    </>
  );

  const renderResponseContent = () => (
    <EVBox p={2}>
      <EVBox>
        <EVBox mt={2} display="flex" justify-content="center" className={styles.iconContainer}>
          <img alt="" src={calendarIcon} className={styles.calendarIcon} />
        </EVBox>
        <EVBox mt={2} textAlign="center" className={styles.responseHeaderLabel}>
          <EVTypography variant="h4">{t('home.rescheduleRequested')}</EVTypography>
        </EVBox>
      </EVBox>
      <EVBox mt={3} mb={5}>
        <EVTypography className={styles.responseMsg} variant="body2">
          {t(rescheduleResponseMsg)}
        </EVTypography>
      </EVBox>
      {renderJobInfo()}
      <EVBox mt={5} className={styles.modalFooter}>
        <EVButton
          onClick={() => closePanel(true)}
          className={styles.footerDoneBtn}
          color="primary"
          variant="contained"
        >
          {t('done')}
        </EVButton>
      </EVBox>
    </EVBox>
  );

  return showResponseContent ? renderResponseContent() : (
    <EVBox p={2}>
      <EVBox display="flex" justifyContent="space-between">
        <EVTypography className={styles.titleText}>{t('home.reschedule')}</EVTypography>
        <EVIconButton className={styles.closeIconButton} onClick={closePanel}>
          <EVClear classes={{ root: styles.closeIcon }} viewBox="0 0 23.25 23.25" data-testid="assignment-close-icon" />
        </EVIconButton>
      </EVBox>
      <EVBox mt={2}>
        <EVTypography className={styles.grayLabel} variant="body2">
          {t('home.rescheduleInfo')}
        </EVTypography>
      </EVBox>
      {renderJobInfo()}
      <EVBox mt={3}>
        <EVTypography className={styles.grayLabel} variant="body2">
          {t('home.jobAppointmentDateLabel')}
        </EVTypography>
      </EVBox>
      <EVBox>
        <EVTypography className={styles.claimDetailLabel}>
          {`${appointmentStartTime.toString()} `}
          <span className={styles.timeZoneLabel}>{`(${t('home.appointmentDateTimezoneLabel')})`}</span>
        </EVTypography>
      </EVBox>
      <EVBox mt={1} className={styles.borderedInputWithLabel}>
        <EVInputLabel className={styles.inputBoxLabel}>{t('home.reasonsLabel')}</EVInputLabel>
        <EVSelect
          value={selectedReason}
          size="small"
          className={styles.select}
          onChange={(e) => selectReason(e.target.value)}
          disableUnderline
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          IconComponent={EVExpandMore}
          inputProps={{
            classes: {
              root: styles.statusSelect,
              icon: styles.iconStatus,
            },
          }}
        >
          {(Object.keys(RESCHEDULE_REASONS).map((item) => (
            <EVMenuItem key={item} className={styles.menuItem} value={item}>
              {t(RESCHEDULE_REASONS[item])}
            </EVMenuItem>
          )))}
        </EVSelect>
      </EVBox>
      <EVBox mt={1} mb={3} className={styles.borderedInputWithLabel}>
        <EVInputLabel className={styles.inputBoxLabel}>{t('home.otherReasonsLabel')}</EVInputLabel>
        <EVTextField
          id="rejectionReason"
          disabled={selectedReason !== OTHERS_OPT}
          className={styles.otherReasonsInput}
          InputProps={{
            disableUnderline: true,
          }}
          size="small"
          variant="standard"
          value={otherReason}
          onChange={(e) => { handleRejectReasonChange(e); }}
        />
      </EVBox>
      {
        isSchedulerEnabled && (
          <EVBox mt={1} className={styles.borderedInputWithLabel}>
            <EVInputLabel className={styles.inputBoxLabel}>{t('home.rescheduleMethodLabel')}</EVInputLabel>
            <EVSelect
              id="rescheduleMethodSelect"
              value={selectedMethod}
              size="small"
              className={styles.select}
              onChange={(e) => selectMethod(e.target.value)}
              disableUnderline
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              IconComponent={EVExpandMore}
              inputProps={{
                classes: {
                  root: styles.statusSelect,
                  icon: styles.iconStatus,
                },
              }}
            >
              {(Object.keys(RESCHEDULE_METHODS).map((item) => (
                <EVMenuItem id={item} key={item} className={styles.menuItem} value={item}>
                  {t(RESCHEDULE_METHODS[item])}
                </EVMenuItem>
              )))}
            </EVSelect>
          </EVBox>
        )
      }
      <EVDivider />
      <EVBox mt={2} className={styles.modalFooter}>
        <EVButton onClick={() => closePanel()} className={styles.footerBackBtn}>
          {t('back')}
        </EVButton>
        <EVButton
          id="submit"
          onClick={submit}
          disabled={selectedReason === OTHERS_OPT && !otherReason.length}
          className={styles.footerDoneBtn}
          color="primary"
          variant="contained"
        >
          {t('home.reschedule')}
        </EVButton>
      </EVBox>
    </EVBox>
  );
};

RescheduleAppointmentDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  claimData: object.isRequired,
  closeDialogPanel: func.isRequired,
};

export default RescheduleAppointmentDialog;
