import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  main: {
    background: theme.palette.grey[300],
    flex: '1 1 auto',
    padding: theme.spacing(2.5),
  },
  paper: {
    height: '87%',
  },
  wrapper: {
    display: 'flex',
    height: '100%',
  },
  claimsLoading: {
    marginLeft: theme.spacing(1.5),
  },
  searchField: {
    width: theme.spacing(47),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.375),
  },
  releaseNotesText: {
    textTransform: 'none',
  },
}));
