import React, { useEffect, useState } from 'react';
import {
  EVBox,
  EVCircularProgress,
  EVToolbarPanel,
  EVIconButton,
  EVMultiStructureIcon,
  EVBadge,
  useTheme,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import PhotosImageViewer from '../photos-imageViewer/PhotosImageViewer';
import useStyles from './PhotosGallery.styles';
import { FETCH_FULL_GALLERY_IMAGE } from '../photos.constant';
import * as action from '../Photos.actions';
import PhotosMultiStructureMenu from '../photos-multistructure-menu';

const PhotosGallery = () => {
  // local state
  const [selectedImage, setSelectedImage] = useState('');
  const [multiStructureMenuOpen, setMultiStructureMenuOpen] = useState();
  const [menuOpen, setMenuOpen] = useState();

  // actions
  const dispatch = useDispatch();
  const fetchFullImageAction = (payload) => dispatch(action.fetchGalleryFullImageAction(payload));

  // selectors
  const sidePanelWidth = useSelector((state) => state.adjusterReducer.sidePanelWidth);
  const galleryTabImage = useSelector((state) => get(state, 'photosReducer.galleryFullImage', ''));
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));
  const selectedGalleryImage = useSelector((state) => get(state, 'photosReducer.selectedImageNameGalleryTab', ''));
  const loading = useSelector((state) => get(state, 'photosReducer.loading', {}));
  const { isMultiStructure = false, structures: structuresList = [] } = useSelector((state) => state.photosReducer.multiStructure);

  const { id: orderId } = useParams();

  // hooks
  const styles = useStyles({ sidePanelWidth });
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(selectedGalleryImage)) setSelectedImage(selectedGalleryImage);
    setMenuOpen(false);
  }, [selectedGalleryImage]);

  const getImageType = (imageName) => {
    const regex = /^S\d+\.JPG$/;
    if (regex.test(imageName) || imageName.includes('coverage')) {
      return 'roof';
    }
    return 'ground';
  };

  useEffect(() => {
    if (!isEmpty(selectedGalleryImage)) {
      fetchFullImageAction({
        orderId,
        imageName: selectedGalleryImage,
        structureId: selectedStructureId,
        imageType: getImageType(selectedGalleryImage),
      });
    }
  }, [selectedImage]);

  const handleMultiStructureMenu = (event) => {
    if (menuOpen) {
      setMultiStructureMenuOpen(null);
    } else {
      setMultiStructureMenuOpen(event.currentTarget);
    }
  };

  return (
    <>
      {
        loading[FETCH_FULL_GALLERY_IMAGE] ? (
          <EVBox
            data-testid="photosGalleryLoader"
            width="100%"
            height="100%"
            color="common.white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EVCircularProgress
              className={styles.circularProgressBar}
            />
          </EVBox>
        ) : (
          <>
            {
          !isEmpty(galleryTabImage) && (
          <PhotosImageViewer
            image={galleryTabImage}
          />
          )
          }
          </>
        )
      }
      <EVBox className={styles.orderToolbar}>
        <EVToolbarPanel
          displayType="vertical"
          iconStyles={{ height: 'auto', borderRadius: '4px' }}
          backgroundColor={theme.palette.common.white}
          style={{ borderRadius: '4px' }}
          border="1px solid #ccc"
        >
          {isMultiStructure && (
          <EVBadge badgeContent={0} color="error">
            <EVIconButton
              data-testid="multiStructureMenu"
              id="multiStructureMenu"
              onClick={handleMultiStructureMenu}
              tooltip={t('photos-ui.selectStructure')}
            >
              <EVMultiStructureIcon color="inherit" />
            </EVIconButton>
          </EVBadge>
          )}
        </EVToolbarPanel>
        <PhotosMultiStructureMenu
          menuOpenEl={multiStructureMenuOpen}
          structures={structuresList}
          setMenuOpen={setMultiStructureMenuOpen}
        />
      </EVBox>
    </>
  );
};

export default PhotosGallery;
