import {
  FETCH_ADJUSTER_DETAILS,
  FETCH_ADJUSTER_DETAILS_FAILURE,
  FETCH_ADJUSTER_DETAILS_SUCCESS,
  FETCH_ADJUSTER_DETAILS_GALLERY,
  FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE,
  FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS,
  /* fetch tags */
  FETCH_ADJUSTER_DETAILS_TAGS,
  FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS,
  FETCH_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* fetch notes */
  FETCH_ADJUSTER_DETAILS_NOTES,
  FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS,
  FETCH_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* save tags */
  SAVE_ADJUSTER_DETAILS_TAGS,
  SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  SAVE_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* save rotation data for images */
  SAVE_ROTATION_FOR_GALLERY_IMAGES,
  SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS,
  SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE,
  /** delete tags */
  DELETE_ADJUSTER_DETAILS_TAGS,
  DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  DELETE_ADJUSTER_DETAILS_TAGS_FAILURE,
  /* save notes */
  SAVE_ADJUSTER_DETAILS_NOTES,
  SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  SAVE_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* delete notes */
  DELETE_ADJUSTER_DETAILS_NOTES,
  DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  DELETE_ADJUSTER_DETAILS_NOTES_FAILURE,
  /* report generator count */
  INCREMENT_GENERATE_REPORT_DEPENDENTS,
  DECREMENT_GENERATE_REPORT_DEPENDENTS,
  /* facet review decision */
  SAVE_FACET_REVIEW_DECISION,
  SAVE_FACET_REVIEW_DECISION_SUCCESS,
  SAVE_FACET_REVIEW_DECISION_FAILURE,
  /* workflow panel */
  SET_WORKFLOW_ANOMALY_ID,
  SET_ANOMALY_DECISION,
  SAVE_ANOMALY_DECISION,
  SAVE_ANOMALY_DECISION_SUCCESS,
  SAVE_ANOMALY_DECISION_FAILURE,
  SET_CURRENT_VIEW,
  /* errors */
  CLEAR_ERRORS,
  FETCH_ROOF_FACETS,
  FETCH_ROOF_FACETS_SUCCESS,
  FETCH_ROOF_FACETS_FAILURE,
  FETCH_ROOF_FACETS_AND_BASE_IMAGE,
  SET_SELECTED_FACET,
  SET_CURRENT_TAB,
  SET_CURRENT_NOTES_TAB,
  POST_REPORT,
  POST_REPORT_FAILURE,
  POST_REPORT_SUCCESS,
  POLL_REPORT,
  POLL_REPORT_FAILURE,
  POLL_REPORT_SUCCESS,
  SET_POLL_START_TIME,
  UNSET_POLL_START_TIME,
  SHOW_REPORT,
  SET_SELECTED_IMAGE,
  FETCH_FACET_BASE_IMAGE,
  FETCH_FACET_BASE_IMAGE_FAILURE,
  FETCH_FACET_BASE_IMAGE_SUCCESS,
  CLOSE_ERROR_TOAST,
  SET_ORDER_ID,
  SET_REPORT_ORDER_ID,
  FETCH_IW_IMAGE_METADATA,
  FETCH_IW_IMAGE_METADATA_FAILURE,
  FETCH_IW_IMAGE_METADATA_SUCCESS,
  FETCH_FACET_IMAGES,
  FETCH_FACET_METADATA,
  FETCH_FACET_IMAGES_SUCCESS,
  FETCH_FACET_IMAGES_FAILURE,
  FETCH_RISK_SHOT,
  FETCH_RISK_SHOT_SUCCESS,
  FETCH_RISK_SHOT_FAILURE,
  /* add user anomaly */
  ADD_USER_ANOMALY,
  ADD_USER_ANOMALY_SUCCESS,
  ADD_USER_ANOMALY_FAILURE,
  DELETE_USER_ANOMALY,
  DELETE_USER_ANOMALY_SUCCESS,
  DELETE_USER_ANOMALY_FAILURE,
  SAVE_ROOF_DECISION,
  SAVE_ROOF_DECISION_SUCCESS,
  SAVE_ROOF_DECISION_FAILURE,
  SET_ROOF_DECISION,
  UPDATE_ADJUSTER_DETAILS_NOTES,
  UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE,
  FETCH_ANOMALY_METADATA,
  FETCH_ANOMALY_METADATA_SUCCESS,
  FETCH_ANOMALY_METADATA_FAILURE,
  SAVE_FACET_REPORT_INCLUSION,
  SAVE_FACET_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_REPORT_INCLUSION_FAILURE,
  SET_FACET_REPORT_INCLUSION,
  FETCH_PRODUCT_COST,
  FETCH_PRODUCT_COST_SUCCESS,
  FETCH_PRODUCT_COST_FAILURE,
  CLEAR_REPORT_STATUS,
  GENERATE_ROOF_REPORT,
  GENERATE_ROOF_REPORT_SUCCESS,
  GENERATE_ROOF_REPORT_FAILURE,
  REGENERATE_REPORT,
  REGENERATE_REPORT_SUCCESS,
  REGENERATE_REPORT_FAILURE,
  FETCH_REPORT_INCLUDED_IMAGES,
  FETCH_REPORT_INCLUDED_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_IMAGES_FAILURE,
  SAVE_IMAGE_REPORT_INCLUSION,
  SAVE_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_IMAGE_REPORT_INCLUSION,
  DELETE_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_IMAGE_REPORT_INCLUSION_FAILURE,
  RESET_ADJUSTER,
  SAVE_FACET_NOTES,
  SAVE_FACET_NOTES_SUCCESS,
  SAVE_FACET_NOTES_FAILURE,
  UPDATE_FACET_NOTES,
  UPDATE_FACET_NOTES_SUCCESS,
  UPDATE_FACET_NOTES_FAILURE,
  DELETE_FACET_NOTES,
  DELETE_FACET_NOTES_SUCCESS,
  DELETE_FACET_NOTES_FAILURE,
  FETCH_FACET_NOTES,
  FETCH_FACET_NOTES_SUCCESS,
  FETCH_FACET_NOTES_FAILURE,
  SAVE_FACET_TEST_SQUARE_NOTES,
  SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  SAVE_FACET_TEST_SQUARE_NOTES_FAILURE,
  UPDATE_FACET_TEST_SQUARE_NOTES,
  UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE,
  FETCH_FACET_TEST_SQUARE_NOTES,
  FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS,
  FETCH_FACET_TEST_SQUARE_NOTES_FAILURE,
  DELETE_FACET_TEST_SQUARE_NOTES,
  DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  DELETE_FACET_TEST_SQUARE_NOTES_FAILURE,
  SET_SELECTED_ANOMALY_VIEW,
  SET_USER,
  FETCH_ROOF_NOTE,
  FETCH_ROOF_NOTE_SUCCESS,
  FETCH_ROOF_NOTE_FAILURE,
  SAVE_ROOF_NOTE,
  SAVE_ROOF_NOTE_SUCCESS,
  SAVE_ROOF_NOTE_FAILURE,
  UPDATE_ROOF_NOTE,
  UPDATE_ROOF_NOTE_SUCCESS,
  UPDATE_ROOF_NOTE_FAILURE,
  DELETE_ROOF_NOTE,
  DELETE_ROOF_NOTE_FAILURE,
  DELETE_ROOF_NOTE_SUCCESS,
  SHOW_ERROR_TOAST,
  SHOW_REPORT_FAILURE,
  SET_SIDE_PANEL_WIDTH,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE,
  SAVE_FACET_NOTE_REPORT_INCLUSION,
  SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE,
  SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE,
  FETCH_SOFT_METAL_IMAGES,
  FETCH_SOFT_METAL_IMAGES_SUCCESS,
  FETCH_SOFT_METAL_IMAGES_FAILURE,
  FETCH_GALLERY_IMAGE_METADATA,
  FETCH_GALLERY_IMAGE_METADATA_SUCCESS,
  FETCH_GALLERY_IMAGE_METADATA_FAILURE,
  COMPLETE_REVIEW_FAILURE,
  COMPLETE_REVIEW,
  // Fetch soft metal image metadata
  FETCH_FACET_IMAGE_METADATA,
  FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS,
  FETCH_ANOMALY_IMAGE_METADATA_SUCCESS,
  FETCH_FACET_IMAGE_METADATA_FAILURE,
  // Confidence Factor
  SAVE_CONFIDENCE_FACTOR,
  SAVE_CONFIDENCE_FACTOR_SUCCESS,
  SAVE_CONFIDENCE_FACTOR_FAILURE,
  // QcPendingPanel
  QC_PANEL_APPROVE,
  QC_PANEL_REJECT,
  QC_PANEL_RETIEPOINT,
  QC_OPS_CHECKOUT_BY_REPORT_ID,
  QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS,
  QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE,
  QC_OPS_CHECKIN_SIMPLE_FAILURE,
  QC_OPS_REJECT_SIMPLE_NOTES_FAILURE,
  QC_OPS_ASSIGN_SELF,
  QC_PANEL_RESCHEDULE,
  FETCH_QC_PANEL_DETAILS,
  FETCH_QC_PANEL_DETAILS_SUCCESS,
  QC_APPROVE_FAILURE,
  QC_REJECT_FAILURE,
  QC_RETIEPOINT_FAILURE,
  QC_RESCHEDULE_FAILURE,
  QC_APPROVED_SUCCESS,
  QC_REJECT_SUCCESS,
  QC_RETIEPOINT_SUCCESS,
  QC_RESCHEDULE_SUCCESS,
  GET_QC_DETAILS_FAILED,
  HANDLE_QC_NOTE_CHANGE,
  HANDLE_QC_REASON_CHANGE,
  HANDLE_QC_REASONS_CHANGE,
  HANDLE_QC_REASONS_EMPTY_REQUEST,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE,
  SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL,
  REJECT_QC_STRUCTURE_API,
  REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION,
  REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION,
  UNDO_QC_STRUCTURE_REJECTION_API,
  UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION,
  UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION,
  CLEAR_SKYDIO_MOSAIC_IMAGE_URL,

  /* display gallery annotations */
  DISPLAY_GALLERY_ANNOTATIONS,
  DISPLAY_GALLERY_ANNOTATIONS_SUCCESS,
  DISPLAY_GALLERY_ANNOTATIONS_FAILURE,
  /* save gallery annotation */
  SAVE_GALLERY_ANNOTATIONS,
  SAVE_GALLERY_ANNOTATIONS_SUCCESS,
  SAVE_GALLERY_ANNOTATIONS_FAILURE,
  /* update gallery annotation */
  UPDATE_GALLERY_ANNOTATIONS,
  UPDATE_GALLERY_ANNOTATIONS_SUCCESS,
  UPDATE_GALLERY_ANNOTATIONS_FAILURE,
  /* delete gallery annotation */
  DELETE_GALLERY_ANNOTATIONS,
  DELETE_GALLERY_ANNOTATIONS_SUCCESS,
  DELETE_GALLERY_ANNOTATIONS_FAILURE,
  /* set gallery image filters */
  SET_APPLIED_GALLERY_IMAGE_FILTERS,
  /* test square status */
  TEST_SQUARE_STATUS_UPDATE,
  TEST_SQUARE_STATUS_UPDATE_SUCCESS,
  TEST_SQUARE_STATUS_UPDATE_FAILURE,
  /* test square location update */
  TEST_SQUARE_LOCATION_UPDATE,
  TEST_SQUARE_LOCATION_UPDATE_SUCCESS,
  TEST_SQUARE_LOCATION_UPDATE_FAILURE,
  /* App settings */
  FETCH_ASSESS_APP_SETTINGS,
  FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  FETCH_ASSESS_APP_SETTINGS_FAILURE,
  /* Anomaly include in report */
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT,
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS,
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE,
  /* Anomaly type update */
  UPDATE_ANOMALY_TYPE,
  UPDATE_ANOMALY_TYPE_SUCCESS,
  UPDATE_ANOMALY_TYPE_FAILURE,
  /* export images */
  EXPORT_ALL_IMAGES,
  EXPORT_ALL_IMAGES_FAILURE,
  EXPORT_REPORT_INCLUDED_IMAGES,
  EXPORT_REPORT_INCLUDED_IMAGES_FAILURE,
  SET_EXPORT_IMAGERY_POLL_START_TIME,
  UNSET_EXPORT_IMAGERY_POLL_START_TIME,
  POLL_EXPORT_IMAGES,
  POLL_EXPORT_IMAGES_SUCCESS,
  POLL_EXPORT_IMAGES_FAILURE,
  DOWNLOAD_EXPORTED_IMAGES,
  DOWNLOAD_EXPORTED_IMAGES_SUCCESS,
  DOWNLOAD_EXPORTED_IMAGES_FAILURE,
  /* fetch report versions */
  FETCH_REPORT_VERSIONS,
  FETCH_REPORT_VERSIONS_SUCCESS,
  FETCH_REPORT_VERSIONS_FAILURE,
  SET_FACET_NOTE_REPORT_INCLUSION,
  SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION,
  SET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  /* fetch feature preferences to be opt out */
  FETCH_FEATURE_PREFERENCE,
  FETCH_FEATURE_PREFERENCE_SUCCESS,
  FETCH_FEATURE_PREFERENCE_FAILURE,
  /* save facet annotation */
  SAVE_FACET_ANNOTATION,
  SAVE_FACET_ANNOTATION_SUCCESS,
  SAVE_FACET_ANNOTATION_FAILURE,
  /* display facet annotations */
  DISPLAY_FACET_ANNOTATIONS,
  DISPLAY_FACET_ANNOTATIONS_SUCCESS,
  DISPLAY_FACET_ANNOTATIONS_FAILURE,
  SET_FACET_BOX_ANNOTATIONS,
  /* delete facet annotation */
  DELETE_FACET_ANNOTATION,
  DELETE_FACET_ANNOTATION_SUCCESS,
  DELETE_FACET_ANNOTATION_FAILURE,
  /* update facet annotations */
  UPDATE_FACET_ANNOTATION,
  UPDATE_FACET_ANNOTATION_SUCCESS,
  UPDATE_FACET_ANNOTATION_FAILURE,
  /* drag and move anomaly */
  UPDATE_USER_DEFINED_ANOMALY_LOCATION,
  UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS,
  UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE,
  /* workflow map navigation */
  ENABLE_WORKFLOW_NAVIGATION,
  SHOW_WORKFLOW_FACET_POLYGON,
  REPLACE_FACET_METADATA,
  SET_WORKFLOW_BASEIMAGE_PARENT,
  SET_WORKFLOW_VIEW_BASE_IMG,
  /* fetch master accessory list */
  FETCH_ACCESSORIES_MASTER_LIST_FAILURE,
  FETCH_ACCESSORIES_MASTER_LIST_SUCCESS,
  FETCH_ACCESSORIES_MASTER_LIST,
  /* fetch accessory summary */
  FETCH_ACCESSORIES_SUMMARY,
  FETCH_ACCESSORIES_SUMMARY_SUCCESS,
  FETCH_ACCESSORIES_SUMMARY_FAILURE,
  /* multi structure */
  SET_SELECTED_STRUCTURE_ID,
  SHOW_REPORT_USING_REPORT_ID,
  FETCH_MULTISTRUCTURE_DECISION,
  FETCH_MULTISTRUCTURE_DECISION_SUCCESS,
  FETCH_MULTISTRUCTURE_DECISION_FAILURE,
  UPDATE_STRUCTURE_VISIT_STATUS,
  UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS,
  UPDATE_STRUCTURE_VISIT_STATUS_FAILURE,

  FETCH_ANOMALY_IMAGES,
  FETCH_ANOMALY_IMAGES_SUCCESS_ACTION,
  FETCH_ANOMALY_IMAGES_FAILURE_ACTION,
  CLEAR_BASE_IMAGE_AND_FACETS,

  SAVE_QC_NOTE,
  SAVE_QC_NOTE_SUCCESS,
  SAVE_QC_NOTE_FAILURE,
  CLEAR_ANNOTATION_TO_EDIT,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  GET_ANOMALY_NOTES,
  GET_ANOMALY_NOTES_SUCCESS,
  GET_ANOMALY_NOTES_FAILURE,
  ADD_ANOMALY_NOTE,
  ADD_ANOMALY_NOTE_SUCCESS,
  ADD_ANOMALY_NOTE_FAILURE,
  UPDATE_ANOMALY_NOTE,
  UPDATE_ANOMALY_NOTE_SUCCESS,
  UPDATE_ANOMALY_NOTE_FAILURE,
  UPDATE_DATE_OF_LOSS,
  UPDATE_DATE_OF_LOSS_SUCCESS,
  UPDATE_DATE_OF_LOSS_FAILURE,
  INCLUDE_ANOMALY_NOTE,
  INCLUDE_ANOMALY_NOTE_SUCCESS,
  INCLUDE_ANOMALY_NOTE_FAILURE,
  SET_FACET_SCAN_IMAGE_ASSETS,
  FETCH_FACET_IMAGE_ANOMALIES,
  FETCH_FACET_IMAGE_ANOMALIES_SUCCESS,
  FETCH_FACET_IMAGE_ANOMALIES_FAILURE,
  SET_RAW_IMAGE_SELECTED_ANOMALY_ID,
  FETCH_SHARED_USERS_LIST,
  FETCH_SHARED_USERS_LIST_SUCCESS,
  FETCH_SHARED_USERS_LIST_FAILURE,
  CREATE_SHARE_LINK,
  CREATE_SHARE_LINK_SUCCESS,
  CREATE_SHARE_LINK_FAILURE,
  DISABLE_GENERATE_REPORT,
  REPORT_ATTEMPTS,
  REPORT_ATTEMPTS_SUCCESS,
  REPORT_ATTEMPTS_FAILURE,
  FETCH_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKEN_COMPLETED,
} from './Adjuster.constants';

// Qc Pending Panel
export const postQcPanelApproveAction = (payload) => ({
  type: QC_PANEL_APPROVE,
  payload,
});
export const postQcPanelRejectAction = (payload) => ({
  type: QC_PANEL_REJECT,
  payload,
});
export const postQcPanelRetiepointAction = (payload) => ({
  type: QC_PANEL_RETIEPOINT,
  payload,
});
export const qcOpsCheckoutByReportId = (payload) => ({
  type: QC_OPS_CHECKOUT_BY_REPORT_ID,
  payload,
});
export const qcOpsCheckoutByReportIdSuccess = (payload) => ({
  type: QC_OPS_CHECKOUT_BY_REPORT_ID_SUCCESS,
  payload,
});
export const qcOpsCheckoutByReportIdFailure = (payload) => ({
  type: QC_OPS_CHECKOUT_BY_REPORT_ID_FAILURE,
  payload,
});
export const qcOpsCheckinSimpleFailure = (payload) => ({
  type: QC_OPS_CHECKIN_SIMPLE_FAILURE,
  payload,
});
export const qcOpsRejectSimpleNotesFailure = (payload) => ({
  type: QC_OPS_REJECT_SIMPLE_NOTES_FAILURE,
  payload,
});
export const qcOpsAssignSelf = (payload) => ({
  type: QC_OPS_ASSIGN_SELF,
  payload,
});
export const postQcPanelRescheduleAction = (payload) => ({
  type: QC_PANEL_RESCHEDULE,
  payload,
});
export const handleQcNoteChange = (payload) => ({
  type: HANDLE_QC_NOTE_CHANGE,
  payload,
});
export const handleQcReasonChange = (payload) => ({
  type: HANDLE_QC_REASON_CHANGE,
  payload,
});
export const handleQcReasonsChange = (payload) => ({
  type: HANDLE_QC_REASONS_CHANGE,
  payload,
});
export const handleQcReasonsEmptyRequest = (payload) => ({
  type: HANDLE_QC_REASONS_EMPTY_REQUEST,
  payload,
});
export const downloadSkydioMosaicImage = (payload) => ({
  type: DOWNLOAD_SKYDIO_MOSAIC_IMAGE,
  payload,
});
export const downloadSkydioMosaicImageSuccessAction = (payload) => ({
  type: DOWNLOAD_SKYDIO_MOSAIC_IMAGE_SUCCESS,
  payload,
});
export const downloadSkydioMosaicImageFailedAction = (payload) => ({
  type: DOWNLOAD_SKYDIO_MOSAIC_IMAGE_FAILURE,
  payload,
});
export const setShowSkydioMosaicImagePanel = (payload) => ({
  type: SET_SHOW_SKYDIO_MOSAIC_IMAGE_PANEL,
  payload,
});
export const rejectQcStructureApi = (payload) => ({
  type: REJECT_QC_STRUCTURE_API,
  payload,
});
export const rejectQcStructureSagaSuccessAction = (payload) => ({
  type: REJECT_QC_STRUCTURE_SAGA_SUCCESS_ACTION,
  payload,
});
export const rejectQcStructureSagaFailureAction = (payload) => ({
  type: REJECT_QC_STRUCTURE_SAGA_FAILURE_ACTION,
  payload,
});
export const undoQcStructureRejectionApi = (payload) => ({
  type: UNDO_QC_STRUCTURE_REJECTION_API,
  payload,
});
export const undoQcStructureRejectionSagaSuccessAction = (payload) => ({
  type: UNDO_QC_STRUCTURE_REJECTION_SAGA_SUCCESS_ACTION,
  payload,
});
export const undoQcStructureRejectionSagaFailureAction = (payload) => ({
  type: UNDO_QC_STRUCTURE_REJECTION_SAGA_FAILURE_ACTION,
  payload,
});
export const fetchProductCost = (payload) => ({
  type: FETCH_PRODUCT_COST,
  payload,
});
export const fetchProductCostSuccess = (payload) => ({
  type: FETCH_PRODUCT_COST_SUCCESS,
  payload,
});
export const fetchProductCostFailure = (payload) => ({
  type: FETCH_PRODUCT_COST_FAILURE,
  payload,
});
/* generate roof report */
export const generateRoofReportAction = (payload) => ({
  type: GENERATE_ROOF_REPORT,
  payload,
});
export const generateRoofReportSuccessAction = (payload) => ({
  type: GENERATE_ROOF_REPORT_SUCCESS,
  payload,
});
export const generateRoofReportFailureAction = (payload) => ({
  type: GENERATE_ROOF_REPORT_FAILURE,
  payload,
});
/* regenerate report */
export const regenerateReportAction = (payload) => ({
  type: REGENERATE_REPORT,
  payload,
});
export const regenerateReportSuccessAction = (payload) => ({
  type: REGENERATE_REPORT_SUCCESS,
  payload,
});
export const regenerateReportFailureAction = (payload) => ({
  type: REGENERATE_REPORT_FAILURE,
  payload,
});
export const fetchAdjusterDetailsAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS,
  payload,
});
export const fetchAdjusterDetailsSuccessAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_SUCCESS,
  payload,
});
export const fetchAdjusterDetailsActionFailure = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_FAILURE,
  payload,
});

export const fetchAdjusterDetailsGalleryAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_GALLERY,
  payload,
});
export const fetchAdjusterDetailsGallerySuccessAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_GALLERY_SUCCESS,
  payload,
});
export const fetchAdjusterDetailsGalleryFailureAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_GALLERY_FAILURE,
  payload,
});

/* save rotation for ground shot images */
export const saveRotationforGalleryImagesAction = (payload) => ({
  type: SAVE_ROTATION_FOR_GALLERY_IMAGES,
  payload,
});
export const saveRotationforGalleryImagesSuccessAction = (payload) => ({
  type: SAVE_ROTATION_FOR_GALLERY_IMAGES_SUCCESS,
  payload,
});
export const saveRotationforGalleryImagesFailureAction = (payload) => ({
  type: SAVE_ROTATION_FOR_GALLERY_IMAGES_FAILURE,
  payload,
});

/* set filters on gallery images */
export const setAppliedGalleryImageFilters = (payload) => ({
  type: SET_APPLIED_GALLERY_IMAGE_FILTERS,
  payload,
});

/* save facet review decision */
export const saveFacetReviewDecisionAction = (payload) => ({
  type: SAVE_FACET_REVIEW_DECISION,
  payload,
});
export const saveFacetReviewDecisionSuccessAction = (payload) => ({
  type: SAVE_FACET_REVIEW_DECISION_SUCCESS,
  payload,
});
export const saveFacetReviewDecisionFailureAction = (payload) => ({
  type: SAVE_FACET_REVIEW_DECISION_FAILURE,
  payload,
});

/* fetch tags and notes */
export const fetchAdjusterDetailsTagsAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_TAGS,
  payload,
});
export const fetchAdjusterDetailsTagsSuccessAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_TAGS_SUCCESS,
  payload,
});
export const fetchAdjusterDetailsTagsFailureAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_TAGS_FAILURE,
  payload,
});

/* fetch tags and notes */
export const fetchAdjusterDetailsNotesAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_NOTES,
  payload,
});
export const fetchAdjusterDetailsNotesSuccessAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_NOTES_SUCCESS,
  payload,
});
export const fetchAdjusterDetailsNotesFailureAction = (payload) => ({
  type: FETCH_ADJUSTER_DETAILS_NOTES_FAILURE,
  payload,
});

/* save tags */
export const saveAdjusterDetailsTagsAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_TAGS,
  payload,
});
export const saveAdjusterDetailsTagsSuccessAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  payload,
});
export const saveAdjusterDetailsTagsFailureAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_TAGS_FAILURE,
  payload,
});

/* delete tags */
export const deleteAdjusterDetailsTagsAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_TAGS,
  payload,
});
export const deleteAdjusterDetailsTagsSuccessAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  payload,
});
export const deleteAdjusterDetailsTagsFailureAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_TAGS_FAILURE,
  payload,
});

/* save note */
export const saveAdjusterDetailsNotesAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_NOTES,
  payload,
});
export const saveAdjusterDetailsNotesSuccessAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  payload,
});
export const saveAdjusterDetailsNotesFailureAction = (payload) => ({
  type: SAVE_ADJUSTER_DETAILS_NOTES_FAILURE,
  payload,
});

/* update note */
export const updateAdjusterDetailsNotesAction = (payload) => ({
  type: UPDATE_ADJUSTER_DETAILS_NOTES,
  payload,
});
export const updateAdjusterDetailsNotesSuccessAction = (payload) => ({
  type: UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  payload,
});
export const updateAdjusterDetailsNotesFailureAction = (payload) => ({
  type: UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE,
  payload,
});

/* delete notes */
export const deleteAdjusterDetailsNotesAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_NOTES,
  payload,
});
export const deleteAdjusterDetailsNotesSuccessAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  payload,
});
export const deleteAdjusterDetailsNotesFailureAction = (payload) => ({
  type: DELETE_ADJUSTER_DETAILS_NOTES_FAILURE,
  payload,
});

/* save facet note */
export const saveFacetNotesAction = (payload) => ({
  type: SAVE_FACET_NOTES,
  payload,
});
export const saveFacetNotesSuccessAction = (payload) => ({
  type: SAVE_FACET_NOTES_SUCCESS,
  payload,
});
export const saveFacetNotesFailureAction = (payload) => ({
  type: SAVE_FACET_NOTES_FAILURE,
  payload,
});

/* update facet note */
export const updateFacetNotesAction = (payload) => ({
  type: UPDATE_FACET_NOTES,
  payload,
});
export const updateFacetNotesSuccessAction = (payload) => ({
  type: UPDATE_FACET_NOTES_SUCCESS,
  payload,
});
export const updateFacetNotesFailureAction = (payload) => ({
  type: UPDATE_FACET_NOTES_FAILURE,
  payload,
});

/* fetch facet note */
export const fetchFacetNotesAction = (payload) => ({
  type: FETCH_FACET_NOTES,
  payload,
});
export const fetchFacetNotesSuccessAction = (payload) => ({
  type: FETCH_FACET_NOTES_SUCCESS,
  payload,
});
export const fetchFacetNotesFailureAction = (payload) => ({
  type: FETCH_FACET_NOTES_FAILURE,
  payload,
});

/* delete facet notes */
export const deleteFacetNotesAction = (payload) => ({
  type: DELETE_FACET_NOTES,
  payload,
});
export const deleteFacetNotesSuccessAction = (payload) => ({
  type: DELETE_FACET_NOTES_SUCCESS,
  payload,
});
export const deleteFacetNotesFailureAction = (payload) => ({
  type: DELETE_FACET_NOTES_FAILURE,
  payload,
});

/* save facet test square note */
export const saveFacetTestSquareNotesAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTES,
  payload,
});
export const saveFacetTestSquareNotesSuccessAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  payload,
});
export const saveFacetTestSquareNotesFailureAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTES_FAILURE,
  payload,
});

/* update facet square note */
export const updateFacetTestSquareNotesAction = (payload) => ({
  type: UPDATE_FACET_TEST_SQUARE_NOTES,
  payload,
});
export const updateFacetTestSquareNotesSuccessAction = (payload) => ({
  type: UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  payload,
});
export const updateFacetTestSquareNotesFailureAction = (payload) => ({
  type: UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE,
  payload,
});

/* fetch facet note */
export const fetchFacetTestSquareNotesAction = (payload) => ({
  type: FETCH_FACET_TEST_SQUARE_NOTES,
  payload,
});
export const fetchFacetTestSquareNotesSuccessAction = (payload) => ({
  type: FETCH_FACET_TEST_SQUARE_NOTES_SUCCESS,
  payload,
});
export const fetchFacetTestSquareNotesFailureAction = (payload) => ({
  type: FETCH_FACET_TEST_SQUARE_NOTES_FAILURE,
  payload,
});

/* delete facet test square notes */
export const deleteFacetTestSquareNotesAction = (payload) => ({
  type: DELETE_FACET_TEST_SQUARE_NOTES,
  payload,
});
export const deleteFacetTestSquareNotesSuccessAction = (payload) => ({
  type: DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  payload,
});
export const deleteFacetTestSquareNotesFailureAction = (payload) => ({
  type: DELETE_FACET_TEST_SQUARE_NOTES_FAILURE,
  payload,
});

/* set report generator dependents */
export const incrementGenerateReportDependents = (payload = {}) => ({
  type: INCREMENT_GENERATE_REPORT_DEPENDENTS,
  payload,
});
export const decrementGenerateReportDependents = (payload = {}) => ({
  type: DECREMENT_GENERATE_REPORT_DEPENDENTS,
  payload,
});
export const fetchRoofFacetsDetailsAndBaseImageAction = (payload) => ({
  type: FETCH_ROOF_FACETS_AND_BASE_IMAGE,
  payload,
});
export const fetchRoofFacetsDetailsAction = (payload) => ({
  type: FETCH_ROOF_FACETS,
  payload,
});
export const fetchRoofFacetsDetailsActionSuccess = (payload) => ({
  type: FETCH_ROOF_FACETS_SUCCESS,
  payload,
});
export const clearRoofFacetAndBaseImage = () => ({
  type: CLEAR_BASE_IMAGE_AND_FACETS,
});
export const fetchRoofFacetsDetailsActionFailure = (payload) => ({
  type: FETCH_ROOF_FACETS_FAILURE,
  payload,
});
export const setSelectedFacetAction = (payload) => ({
  type: SET_SELECTED_FACET,
  payload,
});
export const setCurrentTabAction = (payload) => ({
  type: SET_CURRENT_TAB,
  payload,
});

export const setCurrentNotesTabAction = (payload) => ({
  type: SET_CURRENT_NOTES_TAB,
  payload,
});
export const saveConfidenceFactorAction = (payload) => ({
  type: SAVE_CONFIDENCE_FACTOR,
  payload,
});
export const saveConfidenceFactorSuccessAction = (payload) => ({
  type: SAVE_CONFIDENCE_FACTOR_SUCCESS,
  payload,
});
export const saveConfidenceFactorFailureAction = (payload) => ({
  type: SAVE_CONFIDENCE_FACTOR_FAILURE,
  payload,
});
export const clearErrorsAction = () => ({
  type: CLEAR_ERRORS,
});

export const clearRoofMeasurementReportStatus = () => ({
  type: CLEAR_REPORT_STATUS,
});
export const postReport = (payload) => ({
  type: POST_REPORT,
  payload,
});
export const postReportSuccessAction = (payload) => ({
  type: POST_REPORT_SUCCESS,
  payload,
});
export const postReportActionFailure = (payload) => ({
  type: POST_REPORT_FAILURE,
  payload,
});

export const pollReport = (payload) => ({
  type: POLL_REPORT,
  payload,
});
export const pollReportSuccessAction = (payload) => ({
  type: POLL_REPORT_SUCCESS,
  payload,
});
export const pollReportActionFailure = (payload) => ({
  type: POLL_REPORT_FAILURE,
  payload,
});

export const setPollStartTimeAction = (payload) => ({
  type: SET_POLL_START_TIME,
  payload,
});
export const unsetPollStartTimeAction = (payload) => ({
  type: UNSET_POLL_START_TIME,
  payload,
});

export const showReport = (payload) => ({
  type: SHOW_REPORT,
  payload,
});

export const showReportFailureAction = (payload) => ({
  type: SHOW_REPORT_FAILURE,
  payload,
});

export const showReportUsingReportId = (payload) => ({
  type: SHOW_REPORT_USING_REPORT_ID,
  payload,
});

export const setSelectedImageAction = (payload) => ({
  type: SET_SELECTED_IMAGE,
  payload,
});

export const fetchGalleryImageMetadataAction = (payload) => ({
  type: FETCH_GALLERY_IMAGE_METADATA,
  payload,
});

export const fetchGalleryImageMetadataSuccessAction = (payload) => ({
  type: FETCH_GALLERY_IMAGE_METADATA_SUCCESS,
  payload,
});

export const fetchGalleryImageMetadataFailureAction = (payload) => ({
  type: FETCH_GALLERY_IMAGE_METADATA_FAILURE,
  payload,
});

export const fetchFacetImageMetaAction = (payload) => ({
  type: FETCH_FACET_IMAGE_METADATA,
  payload,
});

export const fetchSoftMetalImageMetadataSuccessAction = (payload) => ({
  type: FETCH_SOFT_METAL_IMAGE_METADATA_SUCCESS,
  payload,
});

export const fetchAnomalyImageMetadataSuccessAction = (payload) => ({
  type: FETCH_ANOMALY_IMAGE_METADATA_SUCCESS,
  payload,
});

export const fetchFacetImageMetadataFailureAction = (payload) => ({
  type: FETCH_FACET_IMAGE_METADATA_FAILURE,
  payload,
});

export const fetchFacetBaseImageAction = (payload) => ({
  type: FETCH_FACET_BASE_IMAGE,
  payload,
});
export const fetchFacetBaseImageSuccessAction = (payload) => ({
  type: FETCH_FACET_BASE_IMAGE_SUCCESS,
  payload,
});
export const fetchFacetBaseImageFailureAction = (payload) => ({
  type: FETCH_FACET_BASE_IMAGE_FAILURE,
  payload,
});

export const closeErrorToast = () => ({
  type: CLOSE_ERROR_TOAST,
});

export const showErrorToastAction = (payload) => ({
  type: SHOW_ERROR_TOAST,
  payload,
});

export const setOrderId = (payload) => ({
  type: SET_ORDER_ID,
  payload,
});

export const setSelectedStructureId = (payload) => ({
  type: SET_SELECTED_STRUCTURE_ID,
  payload,
});

export const setReportOrderId = (payload) => ({
  type: SET_REPORT_ORDER_ID,
  payload,
});

export const fetchIwImageMetadataAction = (payload) => ({
  type: FETCH_IW_IMAGE_METADATA,
  payload,
});
export const fetchIwImageMetadataSuccessAction = (payload) => ({
  type: FETCH_IW_IMAGE_METADATA_SUCCESS,
  payload,
});
export const fetchIwImageMetadataFailureAction = (payload) => ({
  type: FETCH_IW_IMAGE_METADATA_FAILURE,
  payload,
});

/* fetch facet images including metadata */
export const fetchFacetImagesAction = (payload) => ({
  type: FETCH_FACET_IMAGES,
  payload,
});
export const fetchFacetImageMetadataAction = (payload) => ({
  type: FETCH_FACET_METADATA,
  payload,
});
export const fetchFacetImagesSuccessAction = (payload) => ({
  type: FETCH_FACET_IMAGES_SUCCESS,
  payload,
});
export const fetchFacetImagesFailureAction = (payload) => ({
  type: FETCH_FACET_IMAGES_FAILURE,
  payload,
});

/* fetch order risk shot */
export const fetchRiskShotAction = (payload) => ({
  type: FETCH_RISK_SHOT,
  payload,
});
export const fetchRiskShotSuccessAction = (payload) => ({
  type: FETCH_RISK_SHOT_SUCCESS,
  payload,
});
export const fetchRiskShotFailureAction = (payload) => ({
  type: FETCH_RISK_SHOT_FAILURE,
  payload,
});

/* save anomaly decision */
export const setAnomalyDecisionAction = (payload) => ({
  type: SET_ANOMALY_DECISION,
  payload,
});
/* save anomaly decision */
export const saveAnomalyDecisionAction = (payload) => ({
  type: SAVE_ANOMALY_DECISION,
  payload,
});
export const saveAnomalyDecisionSuccessAction = (response, anomalyId, facetId) => ({
  type: SAVE_ANOMALY_DECISION_SUCCESS,
  payload: { response, anomalyId, facetId },
});
export const saveAnomalyDecisionFailureAction = (payload) => ({
  type: SAVE_ANOMALY_DECISION_FAILURE,
  payload,
});

/* save roof decision */
export const saveRoofDecisionAction = (payload) => ({
  type: SAVE_ROOF_DECISION,
  payload,
});
export const saveRoofDecisionSuccessAction = (payload) => ({
  type: SAVE_ROOF_DECISION_SUCCESS,
  payload,
});
export const saveRoofDecisionFailureAction = (payload) => ({
  type: SAVE_ROOF_DECISION_FAILURE,
  payload,
});
export const setRoofDecisionAction = (payload) => ({
  type: SET_ROOF_DECISION,
  payload,
});

/* workflow panel */
export const setAnomalyIdAction = (payload) => ({
  type: SET_WORKFLOW_ANOMALY_ID,
  payload,
});
export const setCurrentViewAction = (payload) => ({
  type: SET_CURRENT_VIEW,
  payload,
});

/* add user anomaly */
export const addUserAnomalyAction = (payload) => ({
  type: ADD_USER_ANOMALY,
  payload,
});

/* drag and move anomaly */
export const updateUserAnomalyLocationAction = (payload) => ({
  type: UPDATE_USER_DEFINED_ANOMALY_LOCATION,
  payload,
});

export const updateUserAnomalyLocationSuccessAction = (payload) => ({
  type: UPDATE_USER_DEFINED_ANOMALY_LOCATION_SUCCESS,
  payload,
});

export const updateUserAnomalyLocationFailureAction = (payload) => ({
  type: UPDATE_USER_DEFINED_ANOMALY_LOCATION_FAILURE,
  payload,
});

export const addUserAnomalySuccessAction = (payload) => ({
  type: ADD_USER_ANOMALY_SUCCESS,
  payload,
});

export const addUserAnomalyFailureAction = (payload) => ({
  type: ADD_USER_ANOMALY_FAILURE,
  payload,
});

export const fetchAnomalyMetadataAction = (payload) => ({
  type: FETCH_ANOMALY_METADATA,
  payload,
});

export const fetchAnomalyMetadataSuccessAction = (payload) => ({
  type: FETCH_ANOMALY_METADATA_SUCCESS,
  payload,
});

export const fetchAnomalyMetadataFailureAction = (payload) => ({
  type: FETCH_ANOMALY_METADATA_FAILURE,
  payload,
});

/* delete user anomaly */
export const deleteUserAnomalyAction = (payload) => ({
  type: DELETE_USER_ANOMALY,
  payload,
});

export const deleteUserAnomalySuccessAction = (payload) => ({
  type: DELETE_USER_ANOMALY_SUCCESS,
  payload,
});

export const deleteUserAnomalyFailureAction = (payload) => ({
  type: DELETE_USER_ANOMALY_FAILURE,
  payload,
});

/* save facet report inclusion */
export const saveFacetReportInclusionAction = (payload) => ({
  type: SAVE_FACET_REPORT_INCLUSION,
  payload,
});

export const saveFacetReportInclusionSuccessAction = (payload) => ({
  type: SAVE_FACET_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const saveFacetReportInclusionFailureAction = (payload) => ({
  type: SAVE_FACET_REPORT_INCLUSION_FAILURE,
  payload,
});

export const setFacetReportInclusionAction = (payload) => ({
  type: SET_FACET_REPORT_INCLUSION,
  payload,
});

/* fetch included images in report */
export const fetchImagesIncludedInReportAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_IMAGES,
  payload,
});

export const fetchImagesIncludedInReportSuccessAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_IMAGES_SUCCESS,
  payload,
});

export const fetchImagesIncludedInReportFailureAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_IMAGES_FAILURE,
  payload,
});

/* save image report inclusion */
export const saveImageReportInclusionAction = (payload) => ({
  type: SAVE_IMAGE_REPORT_INCLUSION,
  payload,
});

export const saveImageReportInclusionSuccessAction = (payload) => ({
  type: SAVE_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const saveImageReportInclusionFailureAction = (payload) => ({
  type: SAVE_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

/* delete image report inclusion */
export const deleteImageReportInclusionAction = (payload) => ({
  type: DELETE_IMAGE_REPORT_INCLUSION,
  payload,
});

export const deleteImageReportInclusionSuccessAction = (payload) => ({
  type: DELETE_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const deleteImageReportInclusionFailureAction = (payload) => ({
  type: DELETE_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const resetAdjusterAction = (payload) => ({
  type: RESET_ADJUSTER,
  payload,
});

export const setSelectedAnomalyViewAction = (payload) => ({
  type: SET_SELECTED_ANOMALY_VIEW,
  payload,
});

export const setUserAction = (payload) => ({
  type: SET_USER,
  payload,
});

export const fetchRoofNoteAction = (payload) => ({
  type: FETCH_ROOF_NOTE,
  payload,
});
export const fetchRoofNoteSuccessAction = (payload) => ({
  type: FETCH_ROOF_NOTE_SUCCESS,
  payload,
});
export const fetchRoofNoteFailureAction = (payload) => ({
  type: FETCH_ROOF_NOTE_FAILURE,
  payload,
});

export const saveRoofNoteAction = (payload) => ({
  type: SAVE_ROOF_NOTE,
  payload,
});
export const saveRoofNoteSuccessAction = (payload) => ({
  type: SAVE_ROOF_NOTE_SUCCESS,
  payload,
});
export const saveRoofNoteFailureAction = (payload) => ({
  type: SAVE_ROOF_NOTE_FAILURE,
  payload,
});

export const updateRoofNoteAction = (payload) => ({
  type: UPDATE_ROOF_NOTE,
  payload,
});
export const updateRoofNoteSuccessAction = (payload) => ({
  type: UPDATE_ROOF_NOTE_SUCCESS,
  payload,
});
export const updateRoofNoteFailureAction = (payload) => ({
  type: UPDATE_ROOF_NOTE_FAILURE,
  payload,
});

export const deleteRoofNoteAction = (payload) => ({
  type: DELETE_ROOF_NOTE,
  payload,
});
export const deleteRoofNoteSuccessAction = (payload) => ({
  type: DELETE_ROOF_NOTE_SUCCESS,
  payload,
});
export const deleteRoofNoteFailureAction = (payload) => ({
  type: DELETE_ROOF_NOTE_FAILURE,
  payload,
});
export const setSidePanelWidthAction = (payload) => ({
  type: SET_SIDE_PANEL_WIDTH,
  payload,
});
export const saveImageNoteReportInclusionAction = (payload) => ({
  type: SAVE_IMAGE_NOTE_REPORT_INCLUSION,
  payload,
});
export const saveImageNoteReportInclusionSuccessAction = (payload) => ({
  type: SAVE_IMAGE_NOTE_REPORT_INCLUSION_SUCCESS,
  payload,
});
export const saveImageNoteReportInclusionFailureAction = (payload) => ({
  type: SAVE_IMAGE_NOTE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const saveFacetNoteReportInclusionAction = (payload) => ({
  type: SAVE_FACET_NOTE_REPORT_INCLUSION,
  payload,
});
export const saveFacetNoteReportInclusionSuccessAction = (payload) => ({
  type: SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS,
  payload,
});
export const saveFacetNoteReportInclusionFailureAction = (payload) => ({
  type: SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const saveFacetTestSquareNoteReportInclusionAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  payload,
});
export const saveFacetTestSquareNoteReportInclusionSuccessAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS,
  payload,
});
export const saveFacetTestSquareNoteReportInclusionFailureAction = (payload) => ({
  type: SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const fetchSoftMetalImagesAction = (payload) => ({
  type: FETCH_SOFT_METAL_IMAGES,
  payload,
});
export const fetchSoftMetalImagesSuccessAction = (payload) => ({
  type: FETCH_SOFT_METAL_IMAGES_SUCCESS,
  payload,
});
export const fetchSoftMetalImagesFailureAction = (payload) => ({
  type: FETCH_SOFT_METAL_IMAGES_FAILURE,
  payload,
});

export const completeReviewAction = (payload) => ({
  type: COMPLETE_REVIEW,
  payload,
});

export const completeReviewFailureAction = (payload) => ({
  type: COMPLETE_REVIEW_FAILURE,
  payload,
});

export const fetchQcPanelDetails = (payload) => ({
  type: FETCH_QC_PANEL_DETAILS,
  payload,
});

export const getQcPanelDetailsSuccess = (payload) => ({
  type: FETCH_QC_PANEL_DETAILS_SUCCESS,
  payload,
});

export const postQcPanelApproveFailureAction = (payload) => ({
  type: QC_APPROVE_FAILURE,
  payload,
});

export const postQcPanelRejectFailureAction = (payload) => ({
  type: QC_REJECT_FAILURE,
  payload,
});

export const postQcPanelRetiepointFailureAction = (payload) => ({
  type: QC_RETIEPOINT_FAILURE,
  payload,
});

export const postQcPanelRescheduleFailureAction = (payload) => ({
  type: QC_RESCHEDULE_FAILURE,
  payload,
});

export const postQcPanelClaimApproveSuccessAction = (payload) => ({
  type: QC_APPROVED_SUCCESS,
  payload,
});

export const postQcPanelClaimRejectSuccessAction = (payload) => ({
  type: QC_REJECT_SUCCESS,
  payload,
});

export const postQcPanelClaimRetiepointSuccessAction = (payload) => ({
  type: QC_RETIEPOINT_SUCCESS,
  payload,
});

export const postQcPanelClaimRescheduleSuccessAction = (payload) => ({
  type: QC_RESCHEDULE_SUCCESS,
  payload,
});

export const displayGalleryAnnotationAction = (payload) => ({
  type: DISPLAY_GALLERY_ANNOTATIONS,
  payload,
});

export const displayGalleryAnnotationSuccessAction = (payload) => ({
  type: DISPLAY_GALLERY_ANNOTATIONS_SUCCESS,
  payload,
});

export const displayGalleryAnnotationFailureAction = (payload) => ({
  type: DISPLAY_GALLERY_ANNOTATIONS_FAILURE,
  payload,
});

export const saveGalleryAnnotationAction = (payload) => ({
  type: SAVE_GALLERY_ANNOTATIONS,
  payload,
});

export const saveGalleryAnnotationSuccessAction = (payload) => ({
  type: SAVE_GALLERY_ANNOTATIONS_SUCCESS,
  payload,
});

export const saveGalleryAnnotationFailureAction = (payload) => ({
  type: SAVE_GALLERY_ANNOTATIONS_FAILURE,
  payload,
});

export const saveFacetAnnotationAction = (payload) => ({
  type: SAVE_FACET_ANNOTATION,
  payload,
});

export const saveFacetAnnotationSuccessAction = (payload) => ({
  type: SAVE_FACET_ANNOTATION_SUCCESS,
  payload,
});

export const saveFacetAnnotationFailureAction = (payload) => ({
  type: SAVE_FACET_ANNOTATION_FAILURE,
  payload,
});

/* display annotation for facets */
export const displayFacetAnnotationAction = (payload) => ({
  type: DISPLAY_FACET_ANNOTATIONS,
  payload,
});

export const displayFacetAnnotationSuccessAction = (payload) => ({
  type: DISPLAY_FACET_ANNOTATIONS_SUCCESS,
  payload,
});

export const displayFacetAnnotationFailureAction = (payload) => ({
  type: DISPLAY_FACET_ANNOTATIONS_FAILURE,
  payload,
});

/* delete facet annotation */
export const deleteFacetAnnotationAction = (payload) => ({
  type: DELETE_FACET_ANNOTATION,
  payload,
});
export const deleteFacetAnnotationSuccessAction = (payload) => ({
  type: DELETE_FACET_ANNOTATION_SUCCESS,
  payload,
});
export const deleteFacetAnnotationFailureAction = (payload) => ({
  type: DELETE_FACET_ANNOTATION_FAILURE,
  payload,
});

/* update annotation for facets */
export const updateFacetAnnotationAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION,
  payload,
});

export const updateFacetAnnotationSuccessAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION_SUCCESS,
  payload,
});

export const updateFacetAnnotationFailureAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION_FAILURE,
  payload,
});

export const updateFacetAnnotationInclInReportAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION_INCL_IN_REPORT,
  payload,
});

export const updateFacetAnnotationInclInReportSuccessAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  payload,
});

export const updateFacetAnnotationInclInReportFailureAction = (payload) => ({
  type: UPDATE_FACET_ANNOTATION_INCL_IN_REPORT_FAILURE,
  payload,
});

export const getAnomalyNotesAction = (payload) => ({
  type: GET_ANOMALY_NOTES,
  payload,
});

export const getAnomalyNotesSuccessAction = (payload) => ({
  type: GET_ANOMALY_NOTES_SUCCESS,
  payload,
});

export const getAnomalyNotesFailureAction = (payload) => ({
  type: GET_ANOMALY_NOTES_FAILURE,
  payload,
});

export const addAnomalyNoteAction = (payload) => ({
  type: ADD_ANOMALY_NOTE,
  payload,
});

export const addAnomalyNoteSuccessAction = (payload) => ({
  type: ADD_ANOMALY_NOTE_SUCCESS,
  payload,
});

export const addAnomalyNoteFailureAction = (payload) => ({
  type: ADD_ANOMALY_NOTE_FAILURE,
  payload,
});

export const updateAnomalyNoteAction = (payload) => ({
  type: UPDATE_ANOMALY_NOTE,
  payload,
});

export const updateAnomalyNoteSuccessAction = (payload) => ({
  type: UPDATE_ANOMALY_NOTE_SUCCESS,
  payload,
});

export const updateAnomalyNoteFailureAction = (payload) => ({
  type: UPDATE_ANOMALY_NOTE_FAILURE,
  payload,
});

export const updateDateOfLossAction = (payload) => ({
  type: UPDATE_DATE_OF_LOSS,
  payload,
});

export const updateDateOfLossSuccessAction = (payload) => ({
  type: UPDATE_DATE_OF_LOSS_SUCCESS,
  payload,
});

export const updateDateOfLossFailureAction = (payload) => ({
  type: UPDATE_DATE_OF_LOSS_FAILURE,
  payload,
});

export const includeAnomalyNoteAction = (payload) => ({
  type: INCLUDE_ANOMALY_NOTE,
  payload,
});

export const includeAnomalyNoteSuccessAction = (payload) => ({
  type: INCLUDE_ANOMALY_NOTE_SUCCESS,
  payload,
});

export const includeAnomalyNoteFailureAction = (payload) => ({
  type: INCLUDE_ANOMALY_NOTE_FAILURE,
  payload,
});

export const setFacetBoxAnnotations = (payload) => ({
  type: SET_FACET_BOX_ANNOTATIONS,
  payload,
});

export const updateGalleryAnnotationAction = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATIONS,
  payload,
});

export const updateGalleryAnnotationSuccessAction = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATIONS_SUCCESS,
  payload,
});

export const updateGalleryAnnotationFailureAction = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATIONS_FAILURE,
  payload,
});

export const updateGalleryAnnotationInclInReport = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT,
  payload,
});

export const updateGalleryAnnotationInclInReportSuccessAction = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_SUCCESS,
  payload,
});

export const updateGalleryAnnotationInclInReportFailureAction = (payload) => ({
  type: UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT_FAILURE,
  payload,
});

export const getQcPanelDetailsSagaFailedAction = (payload) => ({
  type: GET_QC_DETAILS_FAILED,
  payload,
});

export const clearAnnotationToEdit = () => ({
  type: CLEAR_ANNOTATION_TO_EDIT,
});

/* delete gallery annotation */
export const deleteGalleryAnnotationAction = (payload) => ({
  type: DELETE_GALLERY_ANNOTATIONS,
  payload,
});
export const deleteGalleryAnnotationSuccessAction = (payload) => ({
  type: DELETE_GALLERY_ANNOTATIONS_SUCCESS,
  payload,
});
export const deleteGalleryAnnotationFailureAction = (payload) => ({
  type: DELETE_GALLERY_ANNOTATIONS_FAILURE,
  payload,
});

/* test square status update */
export const testSquareStatusUpdateAction = (payload) => ({
  type: TEST_SQUARE_STATUS_UPDATE,
  payload,
});
export const testSquareStatusUpdateSuccessAction = (payload) => ({
  type: TEST_SQUARE_STATUS_UPDATE_SUCCESS,
  payload,
});
export const testSquareStatusUpdateFailureAction = (payload) => ({
  type: TEST_SQUARE_STATUS_UPDATE_FAILURE,
  payload,
});

/* test square location update */
export const testSquareLocationUpdateAction = (payload) => ({
  type: TEST_SQUARE_LOCATION_UPDATE,
  payload,
});
export const testSquareLocationUpdateSuccessAction = (payload) => ({
  type: TEST_SQUARE_LOCATION_UPDATE_SUCCESS,
  payload,
});
export const testSquareLocationUpdateFailureAction = (payload) => ({
  type: TEST_SQUARE_LOCATION_UPDATE_FAILURE,
  payload,
});

/* App settings */
export const fetchAssessAppSettingsAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS,
  payload,
});

export const fetchAssessAppSettingsSuccessAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS_SUCCESS,
  payload,
});

export const fetchAssessAppSettingsFailureAction = (payload) => ({
  type: FETCH_ASSESS_APP_SETTINGS_FAILURE,
  payload,
});

/* TOGGLE_ANOMALY_INCLUDE_IN_REPORT */
export const toggleAnomalyIncludeInReportAction = (payload) => ({
  type: TOGGLE_ANOMALY_INCLUDE_IN_REPORT,
  payload,
});
export const toggleAnomalyIncludeInReportSuccessAction = (payload) => ({
  type: TOGGLE_ANOMALY_INCLUDE_IN_REPORT_SUCCESS,
  payload,
});
export const toggleAnomalyIncludeInReportFailureAction = (payload) => ({
  type: TOGGLE_ANOMALY_INCLUDE_IN_REPORT_FAILURE,
  payload,
});

export const updateAnomalyTypeAction = (payload) => ({
  type: UPDATE_ANOMALY_TYPE,
  payload,
});

export const updateAnomalyTypeSuccessAction = (payload) => ({
  type: UPDATE_ANOMALY_TYPE_SUCCESS,
  payload,
});

export const updateAnomalyTypeFailureAction = (payload) => ({
  type: UPDATE_ANOMALY_TYPE_FAILURE,
  payload,
});

/* Export all images */
export const exportAllImagesAction = (payload) => ({
  type: EXPORT_ALL_IMAGES,
  payload,
});
export const exportAllImagesFailureAction = (payload) => ({
  type: EXPORT_ALL_IMAGES_FAILURE,
  payload,
});

/* Export report included images */
export const exportReportIncludedImagesAction = (payload) => ({
  type: EXPORT_REPORT_INCLUDED_IMAGES,
  payload,
});
export const exportReportIncludedImagesFailureAction = (payload) => ({
  type: EXPORT_REPORT_INCLUDED_IMAGES_FAILURE,
  payload,
});

/* Poll export images */
export const pollExportImagesAction = (payload) => ({
  type: POLL_EXPORT_IMAGES,
  payload,
});
export const pollExportImagesSuccessAction = (payload) => ({
  type: POLL_EXPORT_IMAGES_SUCCESS,
  payload,
});
export const pollExportImagesFailureAction = (payload) => ({
  type: POLL_EXPORT_IMAGES_FAILURE,
  payload,
});

/* Download exported images */
export const downloadExportedImagesAction = (payload) => ({
  type: DOWNLOAD_EXPORTED_IMAGES,
  payload,
});
export const downloadExportedImagesSuccessAction = (payload) => ({
  type: DOWNLOAD_EXPORTED_IMAGES_SUCCESS,
  payload,
});
export const downloadExportedImagesFailureAction = (payload) => ({
  type: DOWNLOAD_EXPORTED_IMAGES_FAILURE,
  payload,
});

export const setExportImageryPollStartTimeAction = (payload) => ({
  type: SET_EXPORT_IMAGERY_POLL_START_TIME,
  payload,
});
export const unsetExportImageryPollStartTimeAction = (payload) => ({
  type: UNSET_EXPORT_IMAGERY_POLL_START_TIME,
  payload,
});

/* fetch report versions */
export const fetchReportVersionsAction = (payload) => ({
  type: FETCH_REPORT_VERSIONS,
  payload,
});
export const fetchReportVersionsSuccessAction = (payload) => ({
  type: FETCH_REPORT_VERSIONS_SUCCESS,
  payload,
});
export const fetchReportVersionsFailureAction = (payload) => ({
  type: FETCH_REPORT_VERSIONS_FAILURE,
  payload,
});

export const setFacetNoteReportInclusionAction = (payload) => ({
  type: SET_FACET_NOTE_REPORT_INCLUSION,
  payload,
});

export const setGalleryImageNoteReportInclusionAction = (payload) => ({
  type: SET_GALLERY_IMAGE_NOTE_REPORT_INCLUSION,
  payload,
});

export const setTestSquareNoteReportInclusionAction = (payload) => ({
  type: SET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  payload,
});

/* fetch feature preferences to be opt out */
export const fetchFeaturePreferenceAction = (payload) => ({
  type: FETCH_FEATURE_PREFERENCE,
  payload,
});
export const fetchFeaturePreferencesSuccessAction = (payload) => ({
  type: FETCH_FEATURE_PREFERENCE_SUCCESS,
  payload,
});
export const fetchFeaturePreferencesFailureAction = (payload) => ({
  type: FETCH_FEATURE_PREFERENCE_FAILURE,
  payload,
});

/* workflow map navigation */
export const enableWorkflowNavigationAction = () => ({
  type: ENABLE_WORKFLOW_NAVIGATION,
});
export const showWorkflowFacetPolygonAction = () => ({
  type: SHOW_WORKFLOW_FACET_POLYGON,
});
export const replaceFacetMetadataAction = (payload) => ({
  type: REPLACE_FACET_METADATA,
  payload,
});
export const setWorkflowBaseImgParentAction = (payload) => ({
  type: SET_WORKFLOW_BASEIMAGE_PARENT,
  payload,
});
export const setWorkflowBaseImgViewAction = () => ({
  type: SET_WORKFLOW_VIEW_BASE_IMG,
});
export const fetchAccessoryMasterListAction = (payload) => ({
  type: FETCH_ACCESSORIES_MASTER_LIST,
  payload,
});
export const fetchAccessoryMasterListSuccessAction = (payload) => ({
  type: FETCH_ACCESSORIES_MASTER_LIST_SUCCESS,
  payload,
});
export const fetchAccessoryMasterListFailureAction = (payload) => ({
  type: FETCH_ACCESSORIES_MASTER_LIST_FAILURE,
  payload,
});
export const fetchAccessorySummaryAction = (payload) => ({
  type: FETCH_ACCESSORIES_SUMMARY,
  payload,
});
export const fetchAccessorySummarySuccessAction = (payload) => ({
  type: FETCH_ACCESSORIES_SUMMARY_SUCCESS,
  payload,
});
export const fetchAccessorySummaryFailureAction = (payload) => ({
  type: FETCH_ACCESSORIES_SUMMARY_FAILURE,
  payload,
});
export const fetchMultiStructureDecisionsAction = (payload) => ({
  type: FETCH_MULTISTRUCTURE_DECISION,
  payload,
});
export const fetchMultiStructureDecisionsSuccessAction = (payload) => ({
  type: FETCH_MULTISTRUCTURE_DECISION_SUCCESS,
  payload,
});
export const fetchMultiStructureDecisionsFailureAction = (payload) => ({
  type: FETCH_MULTISTRUCTURE_DECISION_FAILURE,
  payload,
});
export const updateStructureVisitStatus = (payload) => ({
  type: UPDATE_STRUCTURE_VISIT_STATUS,
  payload,
});
export const updateStructureVisitStatusSuccessAction = (payload) => ({
  type: UPDATE_STRUCTURE_VISIT_STATUS_SUCCESS,
  payload,
});
export const updateStructureVisitStatusFailureAction = (payload) => ({
  type: UPDATE_STRUCTURE_VISIT_STATUS_FAILURE,
  payload,
});

export const fetchAnomalyImagesAction = (payload) => ({
  type: FETCH_ANOMALY_IMAGES,
  payload,
});
export const fetchAnomalyImagesSuccessAction = (payload) => ({
  type: FETCH_ANOMALY_IMAGES_SUCCESS_ACTION,
  payload,
});

export const fetchAnomalyImagesFailureAction = (payload) => ({
  type: FETCH_ANOMALY_IMAGES_FAILURE_ACTION,
  payload,
});

export const clearSkydioMosaicImageUrl = () => ({
  type: CLEAR_SKYDIO_MOSAIC_IMAGE_URL,
});

/* save Qc note */
export const saveQcNoteAction = (payload) => ({
  type: SAVE_QC_NOTE,
  payload,
});
export const saveQcNoteSuccessAction = (payload) => ({
  type: SAVE_QC_NOTE_SUCCESS,
  payload,
});
export const saveQcNoteFailureAction = (payload) => ({
  type: SAVE_QC_NOTE_FAILURE,
  payload,
});

/* fetch included soft metal images in report */
export const fetchSoftMetalImagesIncludedInReportAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES,
  payload,
});

export const fetchSoftMetalImagesIncludedInReportSuccessAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_SUCCESS,
  payload,
});

export const fetchSoftMetalImagesIncludedInReportFailureAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES_FAILURE,
  payload,
});

/* save image report inclusion */
export const saveSoftMetalImageReportInclusionAction = (payload) => ({
  type: SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  payload,
});

export const saveSoftMetalImageReportInclusionSuccessAction = (payload) => ({
  type: SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const saveSoftMetalImageReportInclusionFailureAction = (payload) => ({
  type: SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

/* delete image report inclusion */
export const deleteSoftMetalImageReportInclusionAction = (payload) => ({
  type: DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  payload,
});

export const deleteSoftMetalImageReportInclusionSuccessAction = (payload) => ({
  type: DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const deleteSoftMetalImageReportInclusionFailureAction = (payload) => ({
  type: DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const fetchSkydioImagesIncludedInReportAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SKYDIO_IMAGES,
  payload,
});

export const fetchSkydioImagesIncludedInReportSuccessAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_SUCCESS,
  payload,
});

export const fetchSkydioImagesIncludedInReportFailureAction = (payload) => ({
  type: FETCH_REPORT_INCLUDED_SKYDIO_IMAGES_FAILURE,
  payload,
});

export const saveSkydioImageReportInclusionAction = (payload) => ({
  type: SAVE_SKYDIO_IMAGE_REPORT_INCLUSION,
  payload,
});

export const saveSkydioImageReportInclusionSuccessAction = (payload) => ({
  type: SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const saveSkydioImageReportInclusionFailureAction = (payload) => ({
  type: SAVE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const deleteSkydioImageReportInclusionAction = (payload) => ({
  type: DELETE_SKYDIO_IMAGE_REPORT_INCLUSION,
  payload,
});

export const deleteSkydioImageReportInclusionSuccessAction = (payload) => ({
  type: DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_SUCCESS,
  payload,
});

export const deleteSkydioImageReportInclusionFailureAction = (payload) => ({
  type: DELETE_SKYDIO_IMAGE_REPORT_INCLUSION_FAILURE,
  payload,
});

export const setFacetScanImageAssetsAction = (payload) => ({
  type: SET_FACET_SCAN_IMAGE_ASSETS,
  payload,
});

export const fetchImageAnomaliesAction = (payload) => ({
  type: FETCH_FACET_IMAGE_ANOMALIES,
  payload,
});

export const fetchImageAnomaliesSuccessAction = (payload) => ({
  type: FETCH_FACET_IMAGE_ANOMALIES_SUCCESS,
  payload,
});

export const fetchImageAnomaliesFailureAction = (payload) => ({
  type: FETCH_FACET_IMAGE_ANOMALIES_FAILURE,
  payload,
});

export const setRawImageSelectedAnomalyAction = (payload) => ({
  type: SET_RAW_IMAGE_SELECTED_ANOMALY_ID,
  payload,
});

export const fetchSharedUsersListAction = (payload) => ({
  type: FETCH_SHARED_USERS_LIST,
  payload,
});

export const fetchSharedUsersListSuccessAction = (payload) => ({
  type: FETCH_SHARED_USERS_LIST_SUCCESS,
  payload,
});

export const fetchSharedUsersListFailureAction = (payload) => ({
  type: FETCH_SHARED_USERS_LIST_FAILURE,
  payload,
});

export const createShareLinkAction = (payload) => ({
  type: CREATE_SHARE_LINK,
  payload,
});

export const createShareLinkSuccessAction = (payload) => ({
  type: CREATE_SHARE_LINK_SUCCESS,
  payload,
});

export const createShareLinkFailureAction = (payload) => ({
  type: CREATE_SHARE_LINK_FAILURE,
  payload,
});

export const disableGenerateReportAction = (payload) => ({
  type: DISABLE_GENERATE_REPORT,
  payload,
});

export const getReportAttempts = (payload) => ({
  type: REPORT_ATTEMPTS,
  payload,
});

export const getReportAttemptsSuccessAction = (payload) => ({
  type: REPORT_ATTEMPTS_SUCCESS,
  payload,
});

export const getReportAttemptsFailureAction = (payload) => ({
  type: REPORT_ATTEMPTS_FAILURE,
  payload,
});

export const fetchAccessToken = (payload) => ({
  type: FETCH_ACCESS_TOKEN,
  payload,
});

export const updateAccessToken = (payload) => ({
  type: UPDATE_ACCESS_TOKEN,
  payload,
});

export const updateAccessTokenCompletedAction = () => ({
  type: UPDATE_ACCESS_TOKEN_COMPLETED,
});
